import React, { Component } from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { TextField, InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import Modal from 'react-bootstrap/Modal'
import jwt_decode from "jwt-decode";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Grid,

} from "@material-ui/core";
import Widget from '../../components/Widget/Widget';
import { URL } from '../../config';
import dateFormat from 'dateformat';






const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
});

class HistoryTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      show2: false,
      name: '',
      description: '',
      ip: '',
      user: '',
      password: '',
      id: '',
      resources: [],
      nodes: [],
      networks: [],
      network_name: '',
      resource_name: '',
      resource_nodes: [],
      resource_node: '',
      showMessage: false,
      message: '',
      networks: [],
      letsgo: '',
      errors: [],
      error: false,
      nameError: '',
      ipError: '',
      userError: '',
      passwordError: '',
      endpointError: '',
      typeError: '',
      endpoint: '',
      type: '',
      resourceNodeError: '',
      resourceError: '',
      networkError: '',
      resource_id: '',
      network_id: '',
      network: '',
      emailError: '',
      roleError: '',
      businessError: '',
      email: '',
      role: '',
      business: '',
      userid: '',
      lebusinessId: '',
      resource_node2: '',
      businessName: '',
      currentBusinessName: '',
      imaginarybusinessid: '',
      imaginarybusinessid2: '',
      decodedToken: '',
      page:0,
      rowsPerPage:10

    }



  }



   handleChangePage = (event, newPage) => {
 
    this.setState({ page: newPage });
  };

   handleChangeRowsPerPage = (event) => {
    this.state.rowsPerPage=parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  
  };

  componentDidMount() {

  

    

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    this.setState({ decodedToken: decoded });

    const today = Date.now();

    

  }



  render() {

    const { classes } = this.props;

    var columns = this.props.columns;
    return (
      <div>
     
        <Card style={{width:"100%"}}  className="text-center">
          <Card.Header ><b><h3>Last actions</h3></b></Card.Header>
          <Card.Body>
            <Table style={{width:"100%"}} striped bordered hover>
              <thead>
                <tr>
                  {this.props.columns.map((listValue, index) => {
                    return (
                      <th key={index}>{columns[index]}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {this.props.tabledata.map((listValue, index) => {
                  return (
                    <tr key={index}>
                    
                      <td>{listValue.api}</td>
                      <td style={{maxWidth:250}}>{JSON.stringify(listValue.body)}</td>
                      <td>{dateFormat(listValue.createdAt, "mmmm dS yyyy, hh:mm:ss")}</td>
                      <td>{listValue.email}</td>
                    
                      <td>{JSON.stringify(listValue.queryParams)}</td>
                      <td>{listValue.user}</td>
                      
                    </tr>
                  );
                })}
              </tbody>
            </Table>
      
      
       
     
          </Card.Body>
          
        </Card>
       
        
      </div>
    )

  }
}

HistoryTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HistoryTable);
