import React, { useState, useEffect } from 'react';

import { URL } from '../../config';
import axios from "axios";


import jwt_decode from "jwt-decode";




export default class Orchestrator extends React.Component {

  state = {
    data: [],
    businesses:[],
    currentBusinessName:'',
    businessid:''
  }


  componentDidMount() {

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);



    if (decoded.userType==='SUPER_ADMIN'){ 

      if (localStorage.getItem("imaginarybusinessid") != null) {
  
        var businessid = localStorage.getItem('imaginarybusinessid')
  
      } else {
  
        businessid = decoded.business;
  
      }
  
    } else businessid=decoded.business

    axios.get(URL + `api/network/all?businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        this.setState({ data });
        console.log(this.state.data);
        this.setState({ businessid: businessid });
        if (data.message === 'jwt expired') {

          console.log('signed out');
          localStorage.removeItem("id_token");
          localStorage.removeItem("token");
          localStorage.removeItem("imaginarybusinessid");
          localStorage.removeItem('imaginarybusinessname');
          window.location.replace('/login');
          alert('You have been signed out. Please enter your credentials');

        };

        

        

      })


  }



  rerenderParentCallback = (e) => {
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);

    if (decoded.userType==='SUPER_ADMIN'){ 

      if (localStorage.getItem("imaginarybusinessid") != null) {
  
        var businessid = localStorage.getItem('imaginarybusinessid')
  
      } else {
  
        businessid = decoded.business;
  
      }
  
    } else businessid=decoded.business


    axios.get(URL + `api/network/all?businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        this.setState({ data });
        console.log(this.state.data);
        this.setState({ businessid: businessid });
      


        if (data.message === 'jwt expired') {

          console.log('signed out');
          localStorage.removeItem("id_token");
          localStorage.removeItem("token");
          localStorage.removeItem("imaginarybusinessid");
          localStorage.removeItem('imaginarybusinessname');
          window.location.replace('/login');
          alert('You have been signed out. Please enter your credentials');

        };

      })
    this.setState({});
  }


  render() {

    if (this.state.data.message !== 'jwt expired') {

      return (
        
        <iframe src="https://orchestrator.scudo.app/console" width="100%" height={1000} frameborder="0"></iframe>
 

      )
    } else { return null };
  }
}
