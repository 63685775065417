import React from "react";
import { useEffect } from "react";
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from "react-google-maps";
import jwt_decode from "jwt-decode";
import axios from "axios";



const Map = withScriptjs(withGoogleMap((props) =>

  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: props.latitude, lng: props.longitude }}
  >
    <Marker
      position={{ lat: props.latitude, lng: props.longitude }}
    />
  </GoogleMap>
));

function NodeMap(props) {

  console.log(props)
  const latitude = [44.43, 44.44];
  const longitude = [33.33, 33, 34];
  const [long, setLong] = React.useState('')
  const [lat, setLat] = React.useState('')


  var lt = props.lt;
  var lg = props.lg;


  useEffect((props) => {

    if (lt === null || lt === '' || lt === undefined) lt = 44.43966;

    if (lg === null || lg === '' || lg === undefined) lg = 26.096000;
    setLong(lg)
    setLat(lt)

  }, []);

  return (

    <div style={{ width: "100%", height: "400px" }}>


      <Map
        latitude={parseFloat(lat)}
        longitude={parseFloat(long)}
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&callback=Function.prototype&key=AIzaSyCaE2Mst5NkjsbPyS7JzpIVqdYD1pn0KR0"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
}

export default NodeMap;