import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { URL } from '../../config';
import axios from "axios";
import Customtable from '../network/customtable';
import jwt_decode from "jwt-decode";
import ResourcesTable from './resourcesTable';
import PropTypes from 'prop-types';



// Component's Base CSS
import './index.css';




export default class Resources extends React.Component {

  state = {
    data: [],
    businessid: 'test'
  }

  componentDidMount() {
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);

    if (decoded.userType == 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

        var businessid = localStorage.getItem('imaginarybusinessid')

      } else {

        businessid = decoded.business;

      }

    } else businessid = decoded.business


    axios.get(URL + `api/resource/all?businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        this.setState({ data });
        console.log(this.state.data);
        this.setState({ businessid: businessid });
        console.log(this.state.businessid);

        if (data.message === 'jwt expired') {

          console.log('signed out');
          localStorage.removeItem("id_token");
          localStorage.removeItem("token");
          localStorage.removeItem("imaginarybusinessid");
          localStorage.removeItem("imaginarybusinessname");
          window.location.replace('/login');
          alert('You have been signed out. Please enter your credentials');

        };

      })

  }

rerenderParentCallback = () => {

  var token = localStorage.getItem("token").replace(/['"]+/g, '');
  var decoded = jwt_decode(token);

  if (decoded.userType == 'SUPER_ADMIN') {

    if (localStorage.getItem("imaginarybusinessid") != null) {

      var businessid = localStorage.getItem('imaginarybusinessid')

    } else {

      businessid = decoded.business;

    }

  } else businessid = decoded.business

  axios.get(URL + `api/resource/all?businessId=` + businessid, {
    headers: {
      'Content-Type': 'application/json',
      'token': localStorage.getItem("token").replace(/['"]+/g, '')

    }
  })
    .then(res => {
      const data = res.data;
      this.setState({ data });
      console.log(this.state.data);
      this.setState({ businessid: businessid });
      console.log(this.state.businessid);

      if (data.message === 'jwt expired') {

        console.log('signed out');
        localStorage.removeItem("id_token");
        localStorage.removeItem("token");
        localStorage.removeItem("imaginarybusinessid");
        localStorage.removeItem("imaginarybusinessname");
        window.location.replace('/login');
        alert('You have been signed out. Please enter your credentials');

      };

    })

    this.setState({});

}



  render() {


    if (this.state.data.message !== 'jwt expired') {

      return (


        <ResourcesTable columns={['Name', 'Description', 'Endpoint', 'User', 'Extra Data', 'Type','Port', 'Actions']}
          tablenumber={1}
          tabledata={this.state.data}
          businessid={this.state.businessid}
          rerenderParentCallback={this.rerenderParentCallback}
          currentbusinessname={localStorage.getItem('imaginarybusinessname').replace(/['"]+/g, '')}



        >



        </ResourcesTable>




      )

    } else { return null; }
  }


}

Resources.propTypes = {
  classes: PropTypes.object.isRequired,
};
