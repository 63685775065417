import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { URL } from '../../config';
import axios from "axios";
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Grid,
 
} from "@material-ui/core";
// Component's Base CSS
import './index.css';

import NetworkNodeTable from "./MonitorNodeTable";
import jwt_decode from "jwt-decode";
import { useParams } from 'react-router-dom';
import './index.css';
import Widget from "../../components/Widget/Widget";
import useStyles from "./styles";
import Iframe from './Iframe';

const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
});

 class MonitorNodeDetails extends React.Component {

  state = {
    data: [],
    networks: [],
    resources: [],
    resourceName: '',
    networkName: '',
    resourceId: '',
    networkId: ''
  }

  componentDidMount() {

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    const id = this.props.match.params.id;

    axios.get(URL + `api/networkNodeById?id=` + id + `&&businessId=` + decoded.business, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        this.setState({ data });
        console.log(this.state.data);

        if (data.message === 'jwt expired') {

          console.log('signed out');
          localStorage.removeItem("id_token");
          localStorage.removeItem("token");
          localStorage.removeItem("imaginarybusinessid");
          window.location.replace('/login');
          alert('You have been signed out. Please enter your credentials');

        }


        axios.get(URL + `api/resourceById?id=`+ data.resource_id , {
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem("token").replace(/['"]+/g, '')

          }
        })

          .then(res => {
            const resource = res.data;
            this.setState({resourceName:res.data.data.name})


            axios.get(URL + `api/network/all?businessId=` + decoded.business, {
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem("token").replace(/['"]+/g, '')

              }
            })
              .then(res => {
                const networks = res.data;
                this.setState({ networks: networks });

               




             





                this.setState({ letsgo: 'awfa' });

                
        axios.get(URL + `api/networkById?id=`+ data.network , {
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem("token").replace(/['"]+/g, '')

          }
        }).then(res => {

            this.setState({networkName:res.data.name});

              });
          });
      })
  })

  this.setState({letsgo:"go"});
  };



 








  render() {

    const { classes } = this.props;

    var theUrl="";
   theUrl="https://monitor.scudo.app/d/DWyOTpfZ/network-latency-for-influxdb-2-0?orgId=1&from=now-15m&to=now&var-Host=All&var-Telegraf=zerotier-one-"+this.state.data.network+"&var-Bucket=bucket-"+this.state.data.network+"&refresh=30s&kiosk";
    console.log(theUrl);


    return (
      <div>
      <Grid container spacing={4}>
       <Grid item lg={5} md={7} sm={11} xs={23}>
       <a href="#/app/monitoring"><Button style={{ borderColor: "#605dba", backgroundColor: "#605dba" }}>Back to Monitoring Nodes</Button> </a>
       <p></p>
      <Widget title={<h5>  Details of node <b>{this.state.data.name}</b>:</h5>} 
      upperTitle
      bodyClass={classes.fullHeightBody}
      className={classes.card}>
      <div >
        

        <p>Description: <b>{this.state.data.description}</b></p>
        <p>IP: <b>{this.state.data.ip}</b></p>
        <p>Resource Node: <b>{this.state.data.resource_node}</b></p>
        <p>Resource name: <b>{this.state.resourceName}</b></p>
        <p>Network name: <b>{this.state.networkName}</b></p>
        <p>Status: <b>{this.state.data.status}</b></p>
        <p>Created At: <b>{this.state.data.createdAt}</b></p>
        <p>Updated At: <b>{this.state.data.updatedAt}</b></p>






      </div>
      </Widget>

  

      </Grid>
      </Grid>
      <br>
</br>
<br></br>
<br></br>
<br></br>

          <Widget>
          <Iframe url={theUrl}></Iframe>
        </Widget>
        </div>
    )

  }


  
}

MonitorNodeDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MonitorNodeDetails);
