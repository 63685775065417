import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { URL } from '../../config';
import axios from "axios";

import jwt_decode from "jwt-decode";

import PropTypes from 'prop-types';
import HistoryTable from './HistoryTable';




 

// Component's Base CSS





export default class History extends React.Component {

  constructor(props){

    super(props);
    this.state = {
      data: [],
      businessid: 'test',
      page:0,
      perPage:10,
      count:0
    }

  }

 

 


  componentDidMount() {
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);

    if (decoded.userType == 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

        var businessid = localStorage.getItem('imaginarybusinessid')

      } else {

        businessid = decoded.business;

      }

    } else businessid = decoded.business


    
    var page=this.state.page+1;

    axios.get(URL + `api/lastEvents?page=1&&perPage=10`, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        
        this.setState({data:res.data.rows});
    
        
     

      })

  }




  render() {

    return(
<div>
    <HistoryTable columns={['Api', 'Body','Created at', 'Email', 'Query Params', 'User']}
    tabledata={this.state.data}
  />
   


  </div>
   
    );

}
}

History.propTypes = {
  classes: PropTypes.object.isRequired,
};
