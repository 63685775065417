import React, { Context, Component } from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { TextField, InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import Modal from 'react-bootstrap/Modal'
import './index.css';
import jwt_decode from "jwt-decode";
import PropTypes from 'prop-types';
import {
  Grid,
 
} from "@material-ui/core";

import Widget from '../../components/Widget/Widget';
import { withStyles } from '@material-ui/styles';
import { URL } from '../../config';

const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
});

 class BusinessTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      show2: false,
      name: '',
      description: '',
      ip: '',
      user: '',
      password: '',
      id: '',
      resources: [],
      nodes: [],
      networks: [],
      network_name: '',
      resource_name: '',
      resource_nodes: [],
      resource_node: '',
      showMessage: false,
      message: '',
      networks: [],
      letsgo: '',
      errors: [],
      error: false,
      nameError: '',
      ipError: '',
      userError: '',
      passwordError: '',
      endpointError: '',
      typeError: '',
      endpoint: '',
      type: '',
      resourceNodeError: '',
      resourceError: '',
      networkError: '',
      resource_id: '',
      network_id: '',
      network: '',
      emailError: '',
      roleError: '',
      businessError: '',
      email: '',
      role: '',
      business: '',
      userid: '',
      lebusinessId: '',
      resource_node2: '',
      businessName: '',
      currentBusinessName: '',
      imaginarybusinessid: '',
      imaginarybusinessid2: '',
      showSwitchBusiness:false,
      newBusinessId:'',
      decodedToken:''

    }



  }

  componentDidMount() {

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    this.setState({decodedToken:decoded});

   

   

      if (localStorage.getItem("imaginarybusinessid") != null) {

        this.state.imaginarybusinessid = localStorage.getItem('imaginarybusinessid');
        this.state.imaginarybusinessid2 = localStorage.getItem('imaginarybusinessid');

      } else {

        this.state.imaginarybusinessid = decoded.business;
        this.state.imaginarybusinessid2 = decoded.business;
      }

     
      document.getElementById("b1").innerHTML=this.props.currentBusinessName;
     

  }

  Rerender = (e) => {

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    this.setState({decodedToken:decoded});

    if (localStorage.getItem("imaginarybusinessid") != null) {

      this.state.imaginarybusinessid = localStorage.getItem('imaginarybusinessid');
      this.state.imaginarybusinessid2 = localStorage.getItem('imaginarybusinessid');

    } else {

      this.state.imaginarybusinessid = decoded.business;
      this.state.imaginarybusinessid2 = decoded.business;
    }


    document.getElementById("b1").innerHTML=this.props.currentBusinessName;

  }

  handleClose = () => {
    this.setState({ show: false });
    this.setState({ showMessage: false });
  }

  handleClose2 = () => {
    this.setState({ show2: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  }



  handleDescChange = (e) => {
    this.setState({ description: e.target.value });
  }




  pagereload = (e) => {
    console.log('hei');
    this.setState({ show2: false, show2: false, showMessage: false });
    this.props.rerenderParentCallback();

  }


  handleBusinessDelete = param => e => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      }

    };
    fetch(URL + 'api/deleteBusiness?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Business has been deleted succesfully' });
  }







  createBusiness = () => {



    var error = false;

    this.setState({ nameError: '', descriptionError: '' });

    var data = { name: this.state.name, description: this.state.description };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter an Name' }); error = true }
    if (data.description === '' || data.description === null) { this.setState({ descriptionError: 'Please enter a description' }); error = true }

    this.setState({ show: true });


    if (error === false) {


      this.setState({ show: false });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/addBusiness', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Business has been added succesfully' });
    }



  }











  handleUpdate5 = (param) => e => {

    this.setState({ show2: true, name: param.name, description: param.description, businessId: param.businessId });

  }


  handleShowBusinessSwitch = () => {

    this.setState({showSwitchBusiness:true})

  }

  handleCloseBusinessSwitch = () => {

    this.setState({showSwitchBusiness:false})

  }


  handleBusinessUpdate = () => {

    var error = false;

    this.setState({ nameError: '', descriptionError: '' });

    var data = { name: this.state.name, description: this.state.description };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (data.description === '' || data.description === null) { this.setState({ roleError: 'Please enter a description' }); error = true }

    console.log(data);
    this.setState({ show2: true });


    if (error === false) {
      this.setState({ show: false });
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/businessUpdate?id=' + this.state.businessId, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Business has been updated succesfully' });

    }
  }

  handleAdminBusinessChange = (e) => {

    var businessid = this.state.imaginarybusinessid;
    localStorage.setItem('imaginarybusinessid', businessid);

    this.setState({showSwitchBusiness:false})
    this.Rerender();
    this.props.rerenderParentCallback();

  }


  handleSwitchToThisBusiness = (param) => e => {

    this.setState({ imaginarybusinessid:param });
    var businessid = param;
    localStorage.setItem('imaginarybusinessid', businessid);
    this.Rerender();
    this.props.rerenderParentCallback();
    
    
  }

  handleNewBusinessId = (e) => {
    var businessid = e.target.value;
    this.setState({imaginarybusinessid:businessid});


  }


  render() {

    console.log(this.state.decodedToken);

    var columns = this.props.columns;
    var zadata = [];
    var zadata2 = [];
    for (let i = 0; i < this.state.resources.length; i++) {
      zadata.push({ name: this.state.resources[i].name, id: this.state.resources[i].id });
    }
    for (let i3 = 0; i3 < this.state.networks.length; i3++) {
      zadata2.push({ name: this.state.networks[i3].name, id: this.state.networks[i3].id });
    }

    const { classes } = this.props;

    return (<div>


      <Modal show={this.state.showMessage} onHide={this.pagereload} centered>
        <Modal.Body>{this.state.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.pagereload}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Button style={{borderColor:"#e94e95",backgroundColor:"#e94e95"}} variant="success" size="lg" onClick={this.handleShow} >Create Business</Button>&nbsp;
    
      {this.state.decodedToken.userType=="SUPER_ADMIN"&&
      
      <Button style={{borderColor:"#605dba",backgroundColor:"#605dba"}} variant="primary" size="lg" onClick={this.handleShowBusinessSwitch} >Switch Business</Button>
    
         
    }
   <p></p>
    
    
    
          {this.state.decodedToken.userType=="SUPER_ADMIN"&&  <div>
     
         

<Grid container spacing={4}>

<Grid item lg={9} md={12} sm={21} xs={33}>
<Widget
 disableWidgetMenu={true}
title={"You are currently switched on Business:"}
uppertitle
bodyClass={classes.fullHeightBody}
className={classes.card}
>


    <h5> <b>{this.props.currentBusinessName}</b></h5>


    </Widget>
</Grid>
</Grid>
    </div>
    
    
    
     }
    
      <p></p>
      <Card className="text-center">
        <Card.Header ><b><h3>Businesses</h3></b></Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                {this.props.columns.map((listValue, index) => {
                  return (
                    <th key={index}>{columns[index]}</th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {this.props.tabledata.map((listValue, index) => {
                return (
                  <tr key={index}>
                    <td hidden>{listValue.id}</td>
                    <td>{listValue.name}</td>
                    <td>{listValue.description}</td>
                    <td><Button style={{borderColor:"#493892",backgroundColor:"#493892"}} variant="primary" onClick={this.handleSwitchToThisBusiness(listValue.id)}>Switch to this business</Button>&nbsp;<Button  style={{borderColor:"#605dba",backgroundColor:"#605dba"}} variant="success" onClick={this.handleUpdate5({ show2: true, name: listValue.name, description: listValue.description, businessId: listValue.id })}>Update</Button>&nbsp;<Button style={{borderColor:"#e94e95",backgroundColor:"#e94e95"}} variant="danger" onClick={this.handleBusinessDelete(listValue.id)}>Delete</Button></td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal dialogClassName='custom-dialog' show={this.state.showSwitchBusiness} onHide={this.handleCloseBusinessSwitch} centered>
        <Modal.Header closeButton>
          <Modal.Title>Switch Business</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Switch Business</InputLabel>
              <Select value={this.state.imaginarybusinessid} onChange={this.handleNewBusinessId}
                labelId="demo-simple-select-label2"
                id="demo-simple-select2"
                label="Change Business"
              >
                {this.props.businesses.map((listValue, index) => {
                  return (
                    <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseBusinessSwitch}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleAdminBusinessChange}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal dialogClassName='custom-dialog' show={this.state.show} onHide={this.handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Business</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-box">
            <form onSubmit={this.handleSubmit}>
              <TextField name="Name" label="Enter Name" variant="outlined" onChange={this.handleNameChange} />
              <p style={{ color: 'red' }}>{this.state.nameError}</p>
              <TextField name="Description" label="Enter Description" variant="outlined" onChange={this.handleDescChange} />
              <p style={{ color: 'red' }}>{this.state.descriptionError}</p>
              <p></p>

            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.createBusiness}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal dialogClassName='custom-dialog' show={this.state.show2} onHide={this.handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Business</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-box">
            <form >
              <TextField value={this.state.name} name="Name" label="Enter Name" variant="outlined" onChange={this.handleNameChange} />
              <p style={{ color: 'red' }}>{this.state.nameError}</p>
              <TextField value={this.state.description} name="Description" label="Enter Description" variant="outlined" onChange={this.handleDescChange} />
              <p style={{ color: 'red' }}>{this.state.descriptionError}</p>
              <p></p>
            </form>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleBusinessUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

    );
  }
}

BusinessTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BusinessTable);
