import React, { Component } from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { TextField, InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import axios from "axios";
import Modal from 'react-bootstrap/Modal'
import './index.css';
import jwt_decode from "jwt-decode";
import { mdiConsoleNetwork } from '@mdi/js';
import { PanoramaOutlined } from '@material-ui/icons';
import { URL } from '../../config';

export default class Customtable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      show2: false,
      name: '',
      description: '',
      ip: '',
      user: '',
      password: '',
      id: '',
      resources: [],
      nodes: [],
      networks: [],
      network_name: '',
      resource_name: '',
      resource_nodes: [],
      resource_node: '',
      showMessage: false,
      message: '',
      networks: [],
      letsgo: '',
      errors: [],
      error: false,
      nameError: '',
      ipError: '',
      userError: '',
      passwordError: '',
      endpointError: '',
      typeError: '',
      endpoint: '',
      type: '',
      resourceNodeError: '',
      resourceError: '',
      networkError: '',
      resource_id: '',
      network_id: '',
      network: '',
      emailError: '',
      roleError: '',
      businessError: '',
      email: '',
      role: '',
      business: '',
      userid: '',
      lebusinessId: '',
      resource_node2: '',
      businessName: '',
      currentBusinessName: '',
      imaginarybusinessid: '',
      imaginarybusinessid2: ''

    }



  }

  componentDidMount() {
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    console.log(decoded);
    this.state.lebusinessId = decoded.business;

    if (localStorage.getItem("imaginarybusinessid") != null) {

      this.state.imaginarybusinessid = localStorage.getItem('imaginarybusinessid');
      this.state.imaginarybusinessid2 = localStorage.getItem('imaginarybusinessid');

    } else {

      this.state.imaginarybusinessid = decoded.business;
      this.state.imaginarybusinessid2 = decoded.business;
    }



    axios.get(URL + `api/resource/all?businessId=` + decoded.business, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const resources = res.data;
        this.setState({ resources });
        axios.get(URL + `api/networkNode/all?businessId=` + decoded.business, {
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem("token").replace(/['"]+/g, '')

          }
        })
          .then(res => {
            const nodes = res.data;
            this.setState({ nodes });


            axios.get(URL + `api/network/all?businessId=` + decoded.business, {
              headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem("token").replace(/['"]+/g, '')

              }
            })
              .then(res => {
                const networks = res.data;
                this.setState({ networks });
                this.props.tabledata.forEach(function (v) {
                  this.state.networks.forEach(function (b) {
                    if (v.network === b.id) v.network_name = b.name;
                  }
                    , this)
                }
                  , this)

                this.props.tabledata.forEach(function (x) {

                  this.state.resources.forEach(function (y) {
                    if (x.resource_id === y.id) x.resource_name = y.name;
                  }
                    , this)


                }
                  , this)

                this.setState({ letsgo: 'awfa' });

              }).then(res => {

                axios.get(URL + `api/businessById?id=` + this.state.imaginarybusinessid, {
                  headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem("token").replace(/['"]+/g, '')

                  }
                })
                  .then(res => {

                    const data = res.data;
                    this.setState({ currentBusinessName: data.data.name });
                    console.log(data.data.name);




                  });

              });
          });
      });



  }

  handleClose = () => {
    this.setState({ show: false });
    this.setState({ showMessage: false });
  }

  handleClose2 = () => {
    this.setState({ show2: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  }

  handleSelect = (e) => {
    this.setState({ select: e.target.value });
  }

  handleDescChange = (e) => {
    this.setState({ description: e.target.value });
  }

  handleResourceNodeChange = (e) => {
    this.setState({ resource_node: e.target.value });
  }

  handleResourceChange = (e) => {
    this.setState({ resource_id: e.target.value });
    if (this.state.resources.find(x => x.id === e.target.value).type === 'AWS') { this.state.resource_nodes = []; this.state.resource_nodes.push('EC1'); }
    if (this.state.resources.find(x => x.id === e.target.value).type === 'VMWARE') { this.state.resource_nodes = []; this.state.resource_nodes.push('ROUTER_OS', 'LINUX_UBUNTU'); }
    if (this.state.resources.find(x => x.id === e.target.value).type === 'DOCKER') { this.state.resource_nodes = []; this.state.resource_nodes.push('LINUX_SSH'); }
    if (this.state.resources.find(x => x.id === e.target.value).type === 'Physical') { this.state.resource_nodes = []; this.state.resource_nodes.push('ROUTER_OS', 'LINUX_UBUNTU'); }
    if (this.state.resources.find(x => x.id === e.target.value).type !== 'Physical' && this.state.resources.find(x => x.id === e.target.value).type !== 'DOCKER' && this.state.resources.find(x => x.id === e.target.value).type !== 'VMWARE' && this.state.resources.find(x => x.id === e.target.value).type !== 'AWS') { this.state.resource_nodes = []; }

  }


  handleNetworkChange = (e) => {
    this.setState({ network: e.target.value });
    this.setState({ network_id: e.target.value });
  }

  handleIpChange = (e) => {
    this.setState({ ip: e.target.value });
  }

  handleUserChange = (e) => {
    this.setState({ user: e.target.value });
  }

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });

  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });

  }

  handleRoleChange = (e) => {
    this.setState({ role: e.target.value });

  }

  handleBusinessChange = (e) => {
    this.setState({ imaginarybusinessid2: e.target.value, business: e.target.value });

  }

  handleAdminBusinessChange = (e) => {

    var businessid = e.target.value;
    localStorage.setItem('imaginarybusinessid', businessid);
    window.location.reload();

  }

  handleEndpointChange = (e) => {
    this.setState({ endpoint: e.target.value });
  }

  handlextratext = (e) => {
    this.setState({ extradata: e.target.value });

  }

  changetype = (e) => {
    this.setState({ type: e.target.value });
  }

  pagereload = (e) => {
    console.log('hei');
    window.location.reload();

  }

  createNetwork = () => {

    var error = false;

    this.setState({ nameError: '', ipError: '', userError: '', passwordError: '' });

    var data = { name: this.state.name, description: this.state.description, ip: this.state.ip, network: this.state.network_id, resource_id: this.state.resource_id, user: this.state.user, password: this.state.password, businessId: this.state.lebusinessId };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (data.ip === '' || data.ip === null) { this.setState({ ipError: 'Please enter an IP' }); error = true }
    if (data.user === '' || data.user === null) { this.setState({ userError: 'Please enter a user' }); error = true }
    if (data.password === '' || data.password === null) { this.setState({ passwordError: 'Please enter a password' }); error = true }
    this.setState({ show: true });


    if (error === false) {

      this.setState({ show: false });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/addNetwork', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Network has been added succesfully' });


    }

  }

  handleDelete = param => e => {

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },

    };
    fetch(URL + 'api/networkDelete?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Network has been deleted succesfully' });


  }

  handleResourceDelete = param => e => {

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },

    };
    fetch(URL + 'api/resourceDelete?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Resource has been deleted succesfully' });
  }

  handleUserDelete = param => e => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      }

    };
    fetch(URL + 'api/deleteUser?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.data }));

  }

  handleBusinessDelete = param => e => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      }

    };
    fetch(URL + 'api/deleteBusiness?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Business has been deleted succesfully' });
  }

  handleNodeDelete = param => e => {


    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      }

    };
    fetch(URL + 'api/networkNodeDelete?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Node has been deleted succesfully' });


  }

  createResource = () => {


    var error = false;

    this.setState({ nameError: '', userError: '', passwordError: '', typeError: '', endpointError: '' });

    var data = { name: this.state.name, description: this.state.description, type: this.state.type, user: this.state.user, password: this.state.password, extra_data: this.state.extradata, endpoint: this.state.endpoint, businessId: this.state.lebusinessId };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (data.type === '' || data.type === null) { this.setState({ typeError: 'Please enter a type' }); error = true }
    if (data.user === '' || data.user === null) { this.setState({ userError: 'Please enter a user' }); error = true }
    if (data.password === '' || data.password === null) { this.setState({ passwordError: 'Please enter a password' }); error = true }
    if (data.endpoint === '' || data.endpoint === null) { this.setState({ endpointError: 'Please enter an Endpoint' }); error = true }

    this.setState({ show: true });


    if (error === false) {


      this.setState({ show: false });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/addResource?businessId=4', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Resource has been added succesfully' });
    }

  }

  createUser = () => {


    var error = false;

    this.setState({ emailError: '', roleError: '', passwordError: '', businessError: '' });

    var data = { email: this.state.email, role: this.state.role, password: this.state.password, businessId: this.state.business };
    if (data.email === '' || data.email === null) { this.setState({ emailError: 'Please enter an Email' }); error = true }
    if (data.role === '' || data.role === null) { this.setState({ roleError: 'Please enter a role' }); error = true }
    if (data.password === '' || data.password === null) { this.setState({ passwordError: 'Please enter a password' }); error = true }
    if (data.business === '' || data.business === null) { this.setState({ businessError: 'Please enter a business' }); error = true }

    this.setState({ show: true });


    if (error === false) {


      this.setState({ show: false });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/addUser', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.data }));

    }

  }

  createBusiness = () => {



    var error = false;

    this.setState({ nameError: '', descriptionError: '' });

    var data = { name: this.state.name, description: this.state.description };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter an Name' }); error = true }
    if (data.description === '' || data.description === null) { this.setState({ descriptionError: 'Please enter a description' }); error = true }

    this.setState({ show: true });


    if (error === false) {


      this.setState({ show: false });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/addBusiness', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Business has been added succesfully' });
    }



  }




  createNode = () => {



    var error = false;

    this.setState({ nameError: '', resourceNodeError: '', resourceError: '', networkError: '' });

    var data = { name: this.state.name, description: this.state.description, resource_id: this.state.resource_id, network: this.state.network_id, ip: this.state.ip, resource_node: this.state.resource_node, businessId: this.state.lebusinessId };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (this.state.resource_node === '' || this.state.resource_node === null) { this.setState({ resourceNodeError: 'Please enter a network node' }); error = true }
    if (this.state.resource_id === '' || this.state.resource_id === null) { this.setState({ resourceError: 'Please enter a resource' }); error = true }
    if (this.state.network_id === '' || this.state.network_id === null) { this.setState({ networkError: 'Please enter a network' }); error = true }
    this.setState({ show: true });


    if (error === false) {


      this.setState({ show2: false });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/addNetworkNode', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Node has been added succesfully' });

    }
  }

  handleUpdate = (param) => e => {
    this.setState({ show2: param.show2, name: param.name, description: param.description, ip: param.ip, user: param.user, password: param.password, id: param.id });
  }

  handleUpdate3 = (param) => e => {
    this.setState({ show2: true, name: param.name, description: param.description, endpoint: param.endpoint, user: param.user, extradata: param.extra_data, id: param.id, extradata: param.extradata, type: param.type });
  }

  handleUpdate4 = (param) => e => {





    this.setState({ show2: true, email: param.email, role: param.role, userid: param.userid, business: param.business });
    console.log(param.business);






  }




  handleUpdate5 = (param) => e => {

    this.setState({ show2: true, name: param.name, description: param.description, businessId: param.businessId });
  }




  handleNodeUpdate = (param) => e => {
    this.setState({ show2: true, name: param.name, description: param.description, ip: param.ip, resource_id: param.resource_id, network: param.network, id: param.id, resource_name: param.resource_name, network_name: param.network_name, resource_node: param.resource_node, resource_node2: param.resource_node });
    console.log(this.state.resource_node2);
  }

  handleDestroy = param => e => {

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },
    };
    fetch(URL + 'api/network/destroy?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Network has been destroyed succesfully' });



  }

  handleNodeDestroy = param => e => {

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },
    };
    fetch(URL + 'api/networkNode/destroy?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Node has been destroyed succesfully' });

  }

  handleDeploy = param => e => {



    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },
    };
    fetch(URL + 'api/network/deploy?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Network has been deployed succesfully' });


  }

  handleNodeDeploy = param => e => {


    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },
    };
    fetch(URL + 'api/networkNode/deploy?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Node has been deployed succesfully' });


  }

  handleUpdate2 = () => {


    var error = false;

    this.setState({ nameError: '', ipError: '', userError: '', passwordError: '' });

    var data = { name: this.state.name, description: this.state.description, ip: this.state.ip, user: this.state.user, password: this.state.password, businessId: this.state.lebusinessId };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (data.ip === '' || data.ip === null) { this.setState({ ipError: 'Please enter an IP' }); error = true }
    if (data.user === '' || data.user === null) { this.setState({ userError: 'Please enter a user' }); error = true }
    if (data.password === '' || data.password === null) { this.setState({ passwordError: 'Please enter a password' }); error = true }

    this.setState({ show2: true });


    if (error === false) {

      this.setState({ show2: false });
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/networkUpdate?id=' + this.state.id, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Node has been updated succesfully' });










    }
  }

  handleUpdateResource = () => {



    var error = false;

    this.setState({ nameError: '', userError: '', passwordError: '', endpointError: '', typeError: '' });

    var data = { name: this.state.name, description: this.state.description, user: this.state.user, password: this.state.password, endpoint: this.state.endpoint, extra_data: this.state.extra_data, type: this.state.type, businessId: this.state.lebusinessId };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (data.user === '' || data.user === null) { this.setState({ userError: 'Please enter a user' }); error = true }
    if (data.password === '' || data.password === null) { this.setState({ passwordError: 'Please enter a password' }); error = true }
    if (data.endpoint === '' || data.endpoint === null) { this.setState({ endpointError: 'Please enter a endpoint' }); error = true }
    if (data.type === '' || data.type === null) { this.setState({ typeError: 'Please enter a type' }); error = true }

    console.log(data);

    this.setState({ show2: true });


    if (error === false) {


      this.setState({ show2: false });
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/resourceUpdate?id=' + this.state.id, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Resource has been updated succesfully' });

    }

  }

  handleUpdateNode = () => {

    var error = false;

    this.setState({ nameError: '', resourceNodeError: '', resourceError: '', networkError: '' });

    var data = { name: this.state.name, description: this.state.description, resource_id: this.state.resource_id, network: this.state.network, ip: this.state.ip, resource_node: this.state.resource_node, businessId: this.state.lebusinessId };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (this.state.resource_node === '' || this.state.resource_node === null) { this.setState({ resourceNodeError: 'Please enter a network node' }); error = true }
    if (this.state.resource_id === '' || this.state.resource_id === null) { this.setState({ resourceError: 'Please enter a resource' }); error = true }
    if (this.state.network === '' || this.state.network === null) { this.setState({ networkError: 'Please enter a network' }); error = true }
    console.log(data);
    this.setState({ show2: true });


    if (error === false) {


      this.setState({ show2: false });
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/networkNodeUpdate?id=' + this.state.id, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Node has been Updated' });

    }
  }

  handleUserUpdate = () => {

    var error = false;

    this.setState({ emailError: '', roleError: '', passwordError: '' });

    var data = { email: this.state.email, role: this.state.role, businessId: this.state.business, password: this.state.password };
    if (data.email === '' || data.email === null) { this.setState({ emailError: 'Please enter an email' }); error = true }
    if (data.role === '' || data.role === null) { this.setState({ roleError: 'Please enter a role' }); error = true }
    if (data.businessId === '' || data.businessId === null) { this.setState({ businessError: 'Please enter a business' }); error = true }


    console.log(data);
    this.setState({ show2: true });


    if (error === false) {
      this.setState({ show: false });
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/userUpdate?id=' + this.state.userid, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.message }));





    }
  }

  handleBusinessUpdate = () => {

    var error = false;

    this.setState({ nameError: '', descriptionError: '' });

    var data = { name: this.state.name, description: this.state.description };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (data.description === '' || data.description === null) { this.setState({ roleError: 'Please enter a description' }); error = true }

    console.log(data);
    this.setState({ show2: true });


    if (error === false) {
      this.setState({ show: false });
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/businessUpdate?id=' + this.state.businessId, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Business has been updated succesfully' });

    }
  }

  render() {
    var tablenumber = this.props.tablenumber;
    var columns = this.props.columns;
    var zadata = [];
    var zadata2 = [];
    for (let i = 0; i < this.state.resources.length; i++) {
      zadata.push({ name: this.state.resources[i].name, id: this.state.resources[i].id });
    }
    for (let i3 = 0; i3 < this.state.networks.length; i3++) {
      zadata2.push({ name: this.state.networks[i3].name, id: this.state.networks[i3].id });
    }

    if (tablenumber === 2) {
      return (
        <div>
          <Modal show={this.state.showMessage} onHide={this.pagereload} centered>
            <Modal.Body>{this.state.message}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.pagereload}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Button variant="success" size="lg" onClick={this.handleShow} >Create Network</Button>
          <br></br>
          <p></p>
          <Card className="text-center">
            <Card.Header ><b><h3>Network</h3></b></Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {this.props.columns.map((listValue, index) => {
                      return (
                        <th>{columns[index]}</th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.props.tabledata.map((listValue, index) => {
                    return (
                      <tr key={index}>
                        <td hidden>{listValue.id}</td>
                        <td>{listValue.name}</td>
                        <td>{listValue.description}</td>
                        <td>{listValue.ip}</td>
                        <td>{listValue.user}</td>
                        <td>{listValue.status}</td>
                        {listValue.status == 'ADDED' ? <td> <Button variant="success" onClick={this.handleUpdate({ show2: true, name: listValue.name, description: listValue.description, ip: listValue.ip, user: listValue.user, status: listValue.status, id: listValue.id, password: '' })}>Update</Button>&nbsp;<Button variant="danger" onClick={this.handleDelete(listValue.id)}>Delete</Button>&nbsp;<Button onClick={this.handleDeploy(listValue.id)}>Deploy</Button></td> : null}
                        {listValue.status == 'SUCCESS' ? <td><Button variant="danger" onClick={this.handleDestroy(listValue.id)}>Destroy</Button></td> : null}
                        {listValue.status == 'ERROR' ? <td><Button variant="danger" onClick={this.handleDestroy(listValue.id)}>Destroy</Button></td> : null}
                        {listValue.status == 'IN_PROGRESS' ? <td ><h5>No actions</h5></td> : null}
                      </tr>
                    );
                  })}

                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Modal dialogClassName='custom-dialog' show={this.state.show} onHide={this.handleClose} centered >
            <Modal.Header closeButton>
              <Modal.Title>Create Network</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-box">
                <form onSubmit={this.handleSubmit}>
                  <TextField name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                  <p style={{ color: 'red' }}>{this.state.nameError}</p>
                  <TextField name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                  <p style={{ color: 'red' }}>{this.state.descriptionError}</p>
                  <TextField name="ip" label="Enter ip" variant="outlined" onChange={this.handleIpChange} />
                  <p style={{ color: 'red' }}>{this.state.ipError}</p>
                  <TextField name="user" label="Enter user" variant="outlined" onChange={this.handleUserChange} />
                  <p style={{ color: 'red' }}>{this.state.userError}</p>
                  <TextField name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
                  <p style={{ color: 'red' }}>{this.state.passwordError}</p>
                </form>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={this.createNetwork}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal dialogClassName='custom-dialog' show={this.state.show2} onHide={this.handleClose2} centered>
            <Modal.Header closeButton>
              <Modal.Title>Update Network</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-box">
                <form >
                  <TextField value={this.state.name} name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                  <p style={{ color: 'red' }}>{this.state.nameError}</p>
                  <TextField value={this.state.description} name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                  <p></p>
                  <TextField value={this.state.ip} name="ip" label="Enter ip" variant="outlined" onChange={this.handleIpChange} />
                  <p style={{ color: 'red' }}>{this.state.ipError}</p>
                  <TextField value={this.state.user} name="user" label="Enter user" variant="outlined" onChange={this.handleUserChange} />
                  <p style={{ color: 'red' }}>{this.state.userError}</p>
                  <TextField value={this.state.password} name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
                  <p style={{ color: 'red' }}>{this.state.passwordError}</p>
                </form>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose2}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleUpdate2}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )
    } else if (tablenumber === 1) {
      return (
        <div>
          <Modal show={this.state.showMessage} onHide={this.pagereload} centered>
            <Modal.Body>{this.state.message}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.pagereload}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Button variant="success" size="lg" onClick={this.handleShow} >Create Resource</Button>
          <br></br>
          <p></p>
          <Card className="text-center">
            <Card.Header ><b><h3>Resources</h3></b></Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {this.props.columns.map((listValue, index) => {
                      return (
                        <th key={index}>{columns[index]}</th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.props.tabledata.map((listValue, index) => {
                    return (
                      <tr key={index}>
                        <td hidden>{listValue.id}</td>
                        <td>{listValue.name}</td>
                        <td>{listValue.description}</td>
                        <td>{listValue.endpoint}</td>
                        <td>{listValue.user}</td>
                        <td>{listValue.extra_data}</td>
                        <td>{listValue.type}</td>
                        <td><Button variant="success" onClick={this.handleUpdate3({ show2: true, name: listValue.name, description: listValue.description, endpoint: listValue.endpoint, user: listValue.user, extradata: listValue.extra_data, id: listValue.id, type: listValue.type })}>Update</Button>&nbsp;<Button variant="danger" onClick={this.handleResourceDelete(listValue.id)}>Delete</Button></td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Modal dialogClassName='custom-dialog' show={this.state.show} onHide={this.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Create Resource</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-box">
                <form onSubmit={this.handleSubmit}>
                  <TextField name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                  <p style={{ color: 'red' }}>{this.state.nameError}</p>
                  <TextField name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                  <p></p>
                  <TextField name="endpoint" label="Enter endpoint" variant="outlined" onChange={this.handleEndpointChange} />
                  <p style={{ color: 'red' }}>{this.state.endpointError}</p>
                  <TextField name="user" label="Enter user" variant="outlined" onChange={this.handleUserChange} />
                  <p style={{ color: 'red' }}>{this.state.userError}</p>
                  <TextField name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
                  <p style={{ color: 'red' }}>{this.state.passwordError}</p>
                  <TextField name="extratext" label="Enter extra text" variant="outlined" onChange={this.handlextratext} />
                  <p></p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select onChange={this.changetype}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Type" >
                      <MenuItem value={'VMWARE'}>VMWARE</MenuItem>
                      <MenuItem value={'AWS'}>AWS</MenuItem>
                      <MenuItem value={'DOCKER'}>DOCKER</MenuItem>
                      <MenuItem value={'Physical'}>Physical</MenuItem>
                    </Select>
                  </FormControl>
                  <p style={{ color: 'red' }}>{this.state.typeError}</p>
                </form>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={this.createResource}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal dialogClassName='custom-dialog' show={this.state.show2} onHide={this.handleClose2} centered>
            <Modal.Header closeButton>
              <Modal.Title>Update Resource</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-box">
                <form >
                  <TextField value={this.state.name} name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                  <p style={{ color: 'red' }}>{this.state.nameError}</p>
                  <TextField value={this.state.description} name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                  <p></p>
                  <TextField value={this.state.endpoint} name="endpoint" label="Enter endpoint" variant="outlined" onChange={this.handleEndpointChange} />
                  <p style={{ color: 'red' }}>{this.state.endpointError}</p>
                  <TextField value={this.state.user} name="user" label="Enter user" variant="outlined" onChange={this.handleUserChange} />
                  <p style={{ color: 'red' }}>{this.state.userError}</p>
                  <TextField value={this.state.password} name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
                  <p style={{ color: 'red' }}>{this.state.passwordError}</p>
                  <TextField value={this.state.extradata} name="extratext" label="Enter extra text" variant="outlined" onChange={this.handlextratext} />
                  <p></p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select value={this.state.type} onChange={this.changetype}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Type" >
                      <MenuItem value={'VMWARE'}>VMWARE</MenuItem>
                      <MenuItem value={'AWS'}>AWS</MenuItem>
                      <MenuItem value={'DOCKER'}>DOCKER</MenuItem>
                      <MenuItem value={'Physical'}>Physical</MenuItem>
                    </Select>
                  </FormControl>
                  <p style={{ color: 'red' }}>{this.state.typeError}</p>
                </form>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose2}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleUpdateResource}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )
    } else if (tablenumber === 3) {
      return (
        <div>
          <Modal show={this.state.showMessage} onHide={this.pagereload} centered>
            <Modal.Body>{this.state.message}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.pagereload}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
          <Button variant="success" size="lg" onClick={this.handleShow} >Create Node</Button>
          <br></br>
          <p></p>
          <Card className="text-center">
            <Card.Header ><b><h3>Nodes</h3></b></Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {this.props.columns.map((listValue, index) => {
                      return (
                        <th>{columns[index]}</th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {console.log(this.props.tabledata)}
                  {this.props.tabledata.map((listValue, index) => {
                    return (
                      <tr key={index}>
                        <td hidden>{listValue.id}</td>
                        <td hidden>{listValue.network}</td>
                        <td hidden>{listValue.resource_id}</td>
                        <td>{listValue.name}</td>
                        <td>{listValue.description}</td>
                        <td>{listValue.network_name}</td>
                        <td>{listValue.ip}</td>
                        <td>{listValue.resource_name}</td>
                        <td>{listValue.resource_node}</td>
                        <td>{listValue.status}</td>



                        {listValue.status == 'ADDED' ? <td>  <Button variant="success" onClick={this.handleNodeUpdate({ show2: true, name: listValue.name, description: listValue.description, ip: listValue.ip, network: listValue.network, resource_id: listValue.resource_id, id: listValue.id, resource_name: listValue.resource_name, network_name: listValue.network_name, resource_node: listValue.resource_node })}>Update</Button>&nbsp;<Button variant="danger" onClick={this.handleNodeDelete(listValue.id)}>Delete</Button>&nbsp;<Button onClick={this.handleNodeDeploy(listValue.id)}>Deploy</Button> </td> : null}
                        {listValue.status == 'SUCCESS' ? <td><Button variant="danger" onClick={this.handleNodeDestroy(listValue.id)}>Destroy</Button></td> : null}
                        {listValue.status == 'ERROR' ? <td><Button variant="danger" onClick={this.handleNodeDestroy(listValue.id)}>Destroy</Button></td> : null}
                        {listValue.status == 'IN_PROGRESS' ? <td ><h5>No actions</h5></td> : null}


                      </tr>
                    );
                  })}

                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Modal dialogClassName='custom-dialog' show={this.state.show} onHide={this.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Create Node</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-box">
                <form onSubmit={this.handleSubmit}>
                  <TextField name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                  <p style={{ color: 'red' }}>{this.state.nameError}</p>
                  <TextField name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                  <p></p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Resource</InputLabel>
                    <Select onChange={this.handleResourceChange}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Type"
                    >
                      {zadata.map((listValue, index) => {
                        return (
                          <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                        );
                      })}
                    </Select>
                    <p style={{ color: 'red' }}>{this.state.resourceError}</p>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Resource Node</InputLabel>
                      <Select onChange={this.handleResourceNodeChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Type"
                      >
                        {this.state.resource_nodes.map((listValue, index) => {
                          return (
                            <MenuItem value={listValue}>{listValue}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <p style={{ color: 'red' }}>{this.state.resourceNodeError}</p>

                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Network</InputLabel>
                    <Select onChange={this.handleNetworkChange}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Type">
                      {zadata2.map((listValue, index) => {
                        return (
                          <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p style={{ color: 'red' }}>{this.state.networkError}</p>
                </form>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={this.createNode}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal dialogClassName='custom-dialog' show={this.state.show2} onHide={this.handleClose2} centered>
            <Modal.Header closeButton>
              <Modal.Title>Update Node</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-box">
                <form >
                  <TextField value={this.state.name} name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                  <p style={{ color: 'red' }}>{this.state.nameError}</p>
                  <TextField value={this.state.description} name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                  <p></p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Resource</InputLabel>
                    <Select value={this.state.resource_id} onChange={this.handleResourceChange}
                      labelId="demo-simple-select-label"
                      id="demo-simple-selectt"
                      label="Type"
                    >

                      {zadata.map((listValue, index) => {
                        return (
                          <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                        );
                      })}
                    </Select>
                    <p style={{ color: 'red' }}>{this.state.resourceError}</p>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Resource Node</InputLabel>
                      <Select value={this.state.resource_node} onChange={this.handleResourceNodeChange}
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select2"
                        label="Type"
                      >
                        {this.state.resource_nodes.map((listValue, index) => {
                          return (
                            <MenuItem value={listValue}>{listValue}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <p style={{ color: 'red' }}>{this.state.resourceNodeError}</p>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Network</InputLabel>
                    <Select value={this.state.network} onChange={this.handleNetworkChange}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Type"
                    >
                      {zadata2.map((listValue, index) => {
                        return (
                          <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p style={{ color: 'red' }}>{this.state.networkError}</p>
                </form>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose2}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleUpdateNode}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )
    } else if (tablenumber === 4) {
      return (<div>


        <Modal show={this.state.showMessage} onHide={this.pagereload} centered>
          <Modal.Body>{this.state.message}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.pagereload}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Button variant="success" size="lg" onClick={this.handleShow} >Create User</Button>

        <br></br>
        <br></br>
        <h4>You are currently on Business:{this.state.currentBusinessName}
          <br></br>
          <br></br>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Change Business</InputLabel>
            <Select value={this.state.imaginarybusinessid} onChange={this.handleAdminBusinessChange}
              labelId="demo-simple-select-label2"
              id="demo-simple-select2"
              label="Change Business"
            >
              {this.props.businesses.map((listValue, index) => {
                return (
                  <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                );
              })}
            </Select>
          </FormControl></h4>

        <br></br>
        <p></p>
        <Card className="text-center">
          <Card.Header ><b><h3>Users</h3></b></Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {this.props.columns.map((listValue, index) => {
                    return (
                      <th key={index}>{columns[index]}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {this.props.tabledata.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td hidden>{listValue.id}</td>
                      <td hidden>{listValue.businessId}</td>
                      <td>{listValue.email}</td>
                      <td>{listValue.role}</td>
                      <td>{listValue.createdAt}</td>
                      <td>{listValue.updatedAt}</td>
                      <td><Button variant="success" onClick={this.handleUpdate4({ show2: true, email: listValue.email, role: listValue.role, business: listValue.businessId, userid: listValue.id })}>Update</Button>&nbsp;<Button variant="danger" onClick={this.handleUserDelete(listValue.id)}>Delete</Button></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Modal dialogClassName='custom-dialog' show={this.state.show} onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Create User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-box">
              <form onSubmit={this.handleSubmit}>
                <TextField name="Email" label="Enter Email" variant="outlined" onChange={this.handleEmailChange} />
                <p style={{ color: 'red' }}>{this.state.emailError}</p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select value={this.state.role} onChange={this.handleRoleChange}
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select2"
                    label="Business"
                  >

                    <MenuItem value={'ADMIN'}>{'ADMIN'}</MenuItem>
                    <MenuItem value={'REGULAR_USER'}>{'REGULAR_USER'}</MenuItem>


                  </Select>
                </FormControl>
                <p style={{ color: 'red' }}>{this.state.roleError}</p>
                <TextField name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
                <p style={{ color: 'red' }}>{this.state.passwordError}</p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Business</InputLabel>
                  <Select onChange={this.handleBusinessChange}
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select2"
                    label="Business"
                  >
                    {this.props.businesses.map((listValue, index) => {
                      return (
                        <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <p style={{ color: 'red' }}>{this.state.businessError}</p>

                <p></p>

              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.createUser}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName='custom-dialog' show={this.state.show2} onHide={this.handleClose2} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-box">
              <form >
                <TextField value={this.state.email} name="Email" label="Enter Email" variant="outlined" onChange={this.handleEmailChange} />
                <p style={{ color: 'red' }}>{this.state.emailError}</p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select value={this.state.role} onChange={this.handleRoleChange}
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select2"
                    label="Business"
                  >

                    <MenuItem value={'ADMIN'}>{'ADMIN'}</MenuItem>
                    <MenuItem value={'REGULAR_USER'}>{'REGULAR_USER'}</MenuItem>


                  </Select>
                </FormControl>
                <p style={{ color: 'red' }}>{this.state.roleError}</p>
                <TextField name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Business</InputLabel>

                  <Select value={this.state.imaginarybusinessid2} onChange={this.handleBusinessChange}
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select2"
                    label="Business"
                  >
                    {this.props.businesses.map((listValue, index) => {
                      return (
                        <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <p style={{ color: 'red' }}>{this.state.businessError}</p>
                <p></p>
              </form>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleUserUpdate}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>







      </div>





      );


    } else if (tablenumber === 5) {

      return (<div>


        <Modal show={this.state.showMessage} onHide={this.pagereload} centered>
          <Modal.Body>{this.state.message}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.pagereload}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Button variant="success" size="lg" onClick={this.handleShow} >Create Business</Button>
        <br></br>
        <p></p>
        <Card className="text-center">
          <Card.Header ><b><h3>Businesses</h3></b></Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {this.props.columns.map((listValue, index) => {
                    return (
                      <th key={index}>{columns[index]}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {this.props.tabledata.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td hidden>{listValue.id}</td>
                      <td>{listValue.name}</td>
                      <td>{listValue.description}</td>
                      <td><Button variant="success" onClick={this.handleUpdate5({ show2: true, name: listValue.name, description: listValue.description, businessId: listValue.id })}>Update</Button>&nbsp;<Button variant="danger" onClick={this.handleBusinessDelete(listValue.id)}>Delete</Button></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Modal dialogClassName='custom-dialog' show={this.state.show} onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Create Business</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-box">
              <form onSubmit={this.handleSubmit}>
                <TextField name="Name" label="Enter Name" variant="outlined" onChange={this.handleNameChange} />
                <p style={{ color: 'red' }}>{this.state.nameError}</p>
                <TextField name="Description" label="Enter Description" variant="outlined" onChange={this.handleDescChange} />
                <p style={{ color: 'red' }}>{this.state.descriptionError}</p>
                <p></p>

              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.createBusiness}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName='custom-dialog' show={this.state.show2} onHide={this.handleClose2} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update Business</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-box">
              <form >
                <TextField value={this.state.name} name="Name" label="Enter Name" variant="outlined" onChange={this.handleNameChange} />
                <p style={{ color: 'red' }}>{this.state.nameError}</p>
                <TextField value={this.state.description} name="Description" label="Enter Description" variant="outlined" onChange={this.handleDescChange} />
                <p style={{ color: 'red' }}>{this.state.descriptionError}</p>
                <p></p>
              </form>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleBusinessUpdate}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>







      </div>





      );





    }



  }
}
