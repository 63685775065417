import React, { useState } from "react";
import { useEffect } from "react";
import { URL } from "../../../config";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



// context



function SuperManual(props) {

    var [manual, setManual] = useState(1);
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setManual(event.target.value);
      };

  
    return(
      <div className="App">
         <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
           
        <InputLabel >Show me the:</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={manual}
          onChange={handleChange}
          label="Age"
        >
          
          <MenuItem value={1}>Manual</MenuItem>
          <MenuItem value={2}>Manual Management</MenuItem>
       
        </Select>
      </FormControl>
 

        

        {manual===1 ? <iframe style={{width:"100%",height:"700px"}} src={URL+'manual'}></iframe> : null}
        {manual===2 ? <iframe style={{width:"100%",height:"700px"}} src={URL+'manual-management'}></iframe> : null}

        
      </div>
    );
  }
  

export default SuperManual;
