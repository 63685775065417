import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Link
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
  Block,
} from "@material-ui/icons";

import classNames from "classnames";
import Diamond from './scudo_250x100.png';

// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import BusinessIcon from '@mui/icons-material/Business';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import guv1 from '../../images/guv1.png'
import guv2 from '../../images/guv2.png'
import guv3 from '../../images/guv3.png'

const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

export default function Header(props) {
  var classes = useStyles();
  function nextPath(path) {
    props.history.push(path);
  }
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);

  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);
  var [currentBusinessName, setCurrentBusinessName] = useState('');


  if (localStorage.getItem("token")!=null){
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token); 
    }


    


    useEffect(()=>{

      
      var currentBusinessName=localStorage.getItem('imaginarybusinessname').replace(/['"]+/g, '');
      setCurrentBusinessName(currentBusinessName);
  
  
  },[])


  return (
    <AppBar id="imagecontainer3"  position="fixed" className={classes.appBar}>
      
      <Toolbar className={classes.toolbar}>
      <div>
        

  
    
        </div>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        
    
        
      
        
       
        
        <div marginRight="auto" style={{marginRight:"auto",marginLeft:"auto"}}>
        <img style={{marginRight:60}} width="50" height="50" src={guv3}></img>
        <img style={{marginRight:60}} width="50" height="50" src={guv1}></img>
        <img style={{marginRight:5}} width="50" height="50" src={guv2}></img>
        </div>

        <div style={{color:"#493892",marginTop:"6px"}}>
        <BusinessIcon style={{marginBottom:"5px"}}></BusinessIcon>
        &nbsp;
      
      <b id="b1" margin style={{marginTop:"50px"}}>{currentBusinessName}</b>
        

        </div>
        
        <IconButton 
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon style={{color:"#605dba"}} classes={{ root: classes.headerIcon }} />
        </IconButton>
   
      
       
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h6" weight="medium">
            You are logged in as:
            <br></br>
              <b>{decoded.email}</b>
            </Typography>
         
          </div>
       
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
      
    </AppBar>
  );
}
