import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { URL } from '../../config';
import axios from "axios";

import jwt_decode from "jwt-decode";

import PropTypes from 'prop-types';
import HistoryTable from './HistoryTable';
import TablePagination from '@mui/material/TablePagination';
import Card from 'react-bootstrap/Card'



 

// Component's Base CSS





export default class History extends React.Component {

  constructor(props){

    super(props);
    this.state = {
      data: [],
      businessid: 'test',
      page:0,
      perPage:10,
      count:0
    }

  }

  handleChangePage = (event, newPage) => {
 
    var page=newPage+1;

    
    axios.get(URL + `api/lastEvents?page=`+page+`&&perPage=`+this.state.perPage, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        
        this.setState({data:res.data.rows});
        this.setState({count:res.data.count});
        this.setState({ page: newPage });
        
     

      })

  };

   handleChangeRowsPerPage = (event) => {

   
    var page=this.state.page+1;

    axios.get(URL + `api/lastEvents?page=`+1+`&&perPage=`+parseInt(event.target.value,10), {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        
        
        this.setState({data:res.data.rows,perPage: parseInt(event.target.value,10),page: 0,count:res.data.count});
  
     
    
        
     

      })


  
  };


  componentDidMount() {
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);

    if (decoded.userType == 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

        var businessid = localStorage.getItem('imaginarybusinessid')

      } else {

        businessid = decoded.business;

      }

    } else businessid = decoded.business


    
    var page=this.state.page+1;

    axios.get(URL + `api/lastEvents?page=`+page+`&&perPage=`+this.state.perPage, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        
        this.setState({data:res.data.rows});
        this.setState({count:res.data.count});
        
     

      })

  }




  render() {

    return(
<div>
  
<Card  className="text-center">
          <Card.Header ><b><h3>Last actions</h3></b></Card.Header>
          <Card.Body>
    <HistoryTable columns={['Api', 'Body','Created at', 'Email', 'Query Params', 'User']}
    tabledata={this.state.data}
  />
  
  <TablePagination 
            id="div2"
      component="div"
      count={this.state.count}
      page={this.state.page}
      onPageChange={this.handleChangePage}
      rowsPerPage={this.state.perPage}
      onRowsPerPageChange={this.handleChangeRowsPerPage}
    />
</Card.Body>
</Card>
  </div>
   
    );

}
}

History.propTypes = {
  classes: PropTypes.object.isRequired,
};
