import React, { Component } from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { TextField, InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import axios from "axios";
import Modal from 'react-bootstrap/Modal'
import './index.css';
import { withStyles } from '@material-ui/styles';
import jwt_decode from "jwt-decode";
import { YoutubeSearchedFor } from '@material-ui/icons';
import {
  Grid,
 
} from "@material-ui/core";
import PropTypes from 'prop-types';
import Widget from '../../components/Widget/Widget';
import { URL } from '../../config';
import { Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
});

 class UsersTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      show2: false,
      name: '',
      description: '',
      ip: '',
      user: '',
      password: '',
      id: '',
      resources: [],
      nodes: [],
      networks: [],
      network_name: '',
      resource_name: '',
      resource_nodes: [],
      resource_node: '',
      showMessage: false,
      message: '',
      networks: [],
      letsgo: '',
      errors: [],
      error: false,
      nameError: '',
      ipError: '',
      userError: '', 
      passwordError: '',
      passwordError1: '',
      passwordError2: '',
      endpointError: '',
      typeError: '',
      endpoint: '',
      type: '',
      resourceNodeError: '',
      resourceError: '',
      networkError: '',
      resource_id: '',
      network_id: '',
      network: '',
      emailError: '',
      roleError: '',
      businessError: '',
      email: '',
      role: 'REGULAR_USER',
      business: '',
      userid: '',
      lebusinessId: '',
      resource_node2: '',
      businessName: '',
      currentBusinessName: '',
      imaginarybusinessid: '',
      imaginarybusinessid2: '',
      decodedToken: {},
      reTypedPassword:'',
      retypedPasswordError:''

    }



  }

  componentDidMount() {


    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    this.setState({ decodedToken: decoded,currentBusinessName:decoded.businessName });

   

      if (localStorage.getItem("imaginarybusinessid") != null) {

        this.state.imaginarybusinessid = localStorage.getItem('imaginarybusinessid');
        this.state.imaginarybusinessid2 = localStorage.getItem('imaginarybusinessid');

      } else {

        this.state.imaginarybusinessid = decoded.business;
        this.state.imaginarybusinessid2 = decoded.business;
      }




  }

  Rerender = (e) => {

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);

    if (localStorage.getItem("imaginarybusinessid") != null) {

      this.state.imaginarybusinessid = localStorage.getItem('imaginarybusinessid');
      this.state.imaginarybusinessid2 = localStorage.getItem('imaginarybusinessid');

    } else {

      this.state.imaginarybusinessid = decoded.business;
      this.state.imaginarybusinessid2 = decoded.business;
    }

    this.setState({currentBusinessName:decoded.businessName})



  }

  handleClose = () => {
    this.setState({ show: false });
    this.setState({ showMessage: false });
  }

  handleClose2 = () => {
    this.setState({ show2: false });
  }

  handleShow = () => {
    this.setState({ show: true, passwordError:'', passwordError1:'', passwordError2:'',emailError:'',businessError:'',retypedPasswordError:'' });
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  }

  handleSelect = (e) => {
    this.setState({ select: e.target.value });
  }

  handleDescChange = (e) => {
    this.setState({ description: e.target.value });
  }

  handleResourceNodeChange = (e) => {
    this.setState({ resource_node: e.target.value });
  }


  handleUserChange = (e) => {
    this.setState({ user: e.target.value });
  }

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });

  }

  handleReTypedPassword = (e) => {
    this.setState({ reTypedPassword: e.target.value });

  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });

  }

  handleRoleChange = (e) => {
    this.setState({ role: e.target.value });

  }

  handleBusinessChange = (e) => {
    this.setState({ imaginarybusinessid2: e.target.value, business: e.target.value });

  }

  handleAdminBusinessChange = (e) => {

    var businessid = e.target.value;
    localStorage.setItem('imaginarybusinessid', businessid);

    this.props.rerenderParentCallback();
    this.Rerender();


  }


  changetype = (e) => {
    this.setState({ type: e.target.value });
  }

  pagereload = (e) => {
    console.log('hei');
    this.setState({ show2: false, show2: false, showMessage: false });
    this.props.rerenderParentCallback();
  }







  handleUserDelete = param => e => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      }

    };
    fetch(URL + 'api/deleteUser?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.data }));

  }

   isValidEmailAddress(address) {
    return !! address.match(/.+@.+/);
}




  createUser = () => {


    var error = false;

    this.setState({ emailError: '', roleError: '', passwordError: '', businessError: '', passwordError1:'', passwordError2:'' , reTypedPassword:'',retypedPasswordError:''});

    var data = { email: this.state.email, role: this.state.role, password: this.state.password, businessId: this.state.business, reTypedPassword:this.state.reTypedPassword };
    if (data.email === '' || data.email === null||this.isValidEmailAddress(data.email)===false) { this.setState({ emailError: 'Please enter a valid Email' }); error = true }
    if (data.role === '' || data.role === null) { this.setState({ roleError: 'Please enter a role' }); error = true }
    if (data.businessId === '' || data.business === null) { this.setState({ businessError: 'Please enter a business' }); error = true }
    if (this.isPasswordValid1(data.password)===false) { this.setState({ passwordError: 'Password must have at least 8 characters' }); error = true }
    if (this.isPasswordValid2(data.password)===false) { this.setState({ passwordError1: 'Password must contain at least one uppercase letter'}); error = true }
    if (this.isPasswordValid3(data.password)===false) { this.setState({ passwordError2: 'Password must contain at least one special character'}); error = true }
    if (data.password !== data.reTypedPassword) { this.setState({ retypedPasswordError: 'Passwords do not match' }); error = true }
    
   
    this.setState({ show: true });


    if (error === false) {


      this.setState({ show: false });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/addUser', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.data }));

    }

  }


  handleUpdate = (param) => e => {
    this.setState({ show2: param.show2, name: param.name, description: param.description, ip: param.ip, user: param.user, password: param.password, id: param.id });
  }

  handleUpdate3 = (param) => e => {
    this.setState({ show2: true, name: param.name, description: param.description, endpoint: param.endpoint, user: param.user, extradata: param.extra_data, id: param.id, extradata: param.extradata, type: param.type });
  }

  handleUpdate4 = (param) => e => {
    this.setState({ show2: true, email: param.email, role: param.role, userid: param.userid, business: param.business, passwordError:'',passwordError1:'',passwordError2:'',retypedPasswordError:'' });
    console.log(param.business);
  }


  handleSwitchChange= (param) => e => {

    var data = { email: param.email, businessId: param.business, isActive:param.isactive  };

 

      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/userUpdate?id=' + param.userid, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.data }));



  
  }

  handleUpdate5 = (param) => e => {
    this.setState({ show2: true, name: param.name, description: param.description, businessId: param.businessId });
  }


  handleUserUpdate = () => {

    var error = false;

    this.setState({ emailError: '', roleError: '', passwordError: '', reTypedPassword:'', passwordError1:'', passwordError2:'', retypedPasswordError:'' });

    var data = { email: this.state.email, role: this.state.role, businessId: this.state.business, password: this.state.password, reTypedPassword:this.state.reTypedPassword };
    if (data.email === '' || data.email === null||this.isValidEmailAddress(data.email)===false) { this.setState({ emailError: 'Please enter a valid email' }); error = true }
    if (data.role === '' || data.role === null) { this.setState({ roleError: 'Please enter a role' }); error = true }
    if (data.businessId === '' || data.businessId === null) { this.setState({ businessError: 'Please enter a business' }); error = true }
    if (this.isPasswordValid1(data.password)===false) { this.setState({ passwordError: 'Password must have at least 8 characters' }); error = true }
    if (this.isPasswordValid2(data.password)===false) { this.setState({ passwordError1: 'Password must contain at least one uppercase letter'}); error = true }
     if (this.isPasswordValid3(data.password)===false) { this.setState({ passwordError2: 'Password must contain at least one special character'}); error = true }
     if (data.password !== data.reTypedPassword) { this.setState({ retypedPasswordError: 'Passwords do not match' }); error = true }
    
 
    console.log(data);
    this.setState({ show2: true });


    if (error === false) {
      this.setState({ show: false });
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/userUpdate?id=' + this.state.userid, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.data }));





    }
  }

   isPasswordValid1 = (password) => {
    // Check if password is at least 8 characters long
    if (password.length < 8) {
      return false;
    } else return true;
    
  }

  isPasswordValid2 = (password) => {

    // Check if password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    } else return true;

  }
    
    isPasswordValid3 = (password) => {

    // Check if password contains at least one special character
    if (!/[\W_]/.test(password)) {
      return false;
    } else return true;
     
  
  }


  validatePassword = (e) => {

    console.log(this.isPasswordValid(e.target.value));

  }

  render() {
    const { classes } = this.props;
    var columns = this.props.columns;
    return (<div>


      <Modal show={this.state.showMessage} onHide={this.pagereload} centered>
        <Modal.Body>{this.state.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.pagereload}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    
      <Button style={{borderColor:"#e94e95",backgroundColor:"#e94e95"}} variant="success" size="lg" onClick={this.handleShow} >Create User</Button>

    

      <p></p>

      

      {this.state.decodedToken.userType=="SUPER_ADMIN"&&  <div>


      <Grid container spacing={4}>

      <Grid item lg={9} md={12} sm={21} xs={33}>
  <Widget
   disableWidgetMenu={true}
    title={"You are currently switched on Business:"}
    uppertitle
    bodyClass={classes.fullHeightBody}
    className={classes.card}
  >


          <h5> <b>{this.props.currentbusinessname}</b></h5>


          </Widget>
      </Grid>
      </Grid>
          </div>
          
          
          
           }

        

     

      <p></p>

      <Card className="text-center">
        <Card.Header ><b><h3>Users</h3></b></Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                {this.props.columns.map((listValue, index) => {
                  return (
                    <th key={index}>{columns[index]}</th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {this.props.tabledata.map((listValue, index) => {
                return (
                  <tr key={index}>
                    <td hidden>{listValue.id}</td>
                    <td hidden>{listValue.businessId}</td>
                    <td>{listValue.email}</td>
                    <td>{listValue.role}</td>
                    <td>{listValue.createdAt}</td>
                    <td>{listValue.updatedAt}</td>
                    <td>{listValue.isActive===0 ?   <FormControlLabel control={<Switch onChange={this.handleSwitchChange({isactive:1,email: listValue.email, business: listValue.businessId,userid: listValue.id})}  />} label="NO" /> :  
                     <FormControlLabel control={<Switch defaultChecked onChange={this.handleSwitchChange({isactive:0,email: listValue.email,business: listValue.businessId,userid: listValue.id})} />} label="YES" />}</td>
                    <td><Button style={{borderColor:"#605dba",backgroundColor:"#605dba"}} variant="success" onClick={this.handleUpdate4({ show2: true, email: listValue.email, role: listValue.role, business: listValue.businessId, userid: listValue.id })}>Update</Button>&nbsp;<Button style={{borderColor:"#e94e95",backgroundColor:"#e94e95"}} variant="danger" onClick={this.handleUserDelete(listValue.id)}>Delete</Button></td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal dialogClassName='custom-dialog' show={this.state.show} onHide={this.handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-box">
            <form onSubmit={this.handleSubmit}>
              <TextField name="Email" label="Enter Email" variant="outlined" onChange={this.handleEmailChange} />
              <p style={{ color: 'red' }}>{this.state.emailError}</p>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select value={this.state.role} onChange={this.handleRoleChange}
                  labelId="demo-simple-select-label2"
                  id="demo-simple-select2"
                  label="Business"
                >
                  
                  <MenuItem value={'ADMIN'}>{'ADMIN'}</MenuItem>
                  <MenuItem value={'REGULAR_USER'}>{'REGULAR_USER'}</MenuItem>
              


                </Select>
              </FormControl>
              <p style={{ color: 'red' }}>{this.state.roleError}</p>
              <TextField type="password" name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
              <p style={{ color: 'red' }}>{this.state.passwordError}</p>
              <p style={{ color: 'red' }}>{this.state.passwordError1}</p>
              <p style={{ color: 'red' }}>{this.state.passwordError2}</p>
              <TextField type="password" name="retType" label="Re enter password" variant="outlined" onChange={this.handleReTypedPassword} />
              <p style={{ color: 'red' }}>{this.state.retypedPasswordError}</p>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Business</InputLabel>
                <Select value={this.state.imaginarybusinessid2} onChange={this.handleBusinessChange}
                  labelId="demo-simple-select-label2"
                  id="demo-simple-select2"
                  label="Business"
                >


                  {this.state.decodedToken.userType=="SUPER_ADMIN" && this.props.businesses.map((listValue, index) => {
                    return (
                      <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                    );
                  })}

                {this.state.decodedToken.userType!="SUPER_ADMIN" && 
                
                      <MenuItem value={this.state.decodedToken.business}>{this.state.decodedToken.businessName}</MenuItem>
                    
                  }


                </Select>
              </FormControl>
              <p style={{ color: 'red' }}>{this.state.businessError}</p>

              <p></p>

            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.createUser}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal dialogClassName='custom-dialog' show={this.state.show2} onHide={this.handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-box">
            <form >
              <TextField value={this.state.email} name="Email" label="Enter Email" variant="outlined" onChange={this.handleEmailChange} />
              <p style={{ color: 'red' }}>{this.state.emailError}</p>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select value={this.state.role} onChange={this.handleRoleChange}
                  labelId="demo-simple-select-label2"
                  id="demo-simple-select2"
                  label="Business"
                >

                  <MenuItem value={'ADMIN'}>{'ADMIN'}</MenuItem>
                  <MenuItem value={'REGULAR_USER'}>{'REGULAR_USER'}</MenuItem>
                  <MenuItem value={'SUPER_ADMIN'}>{'SUPER_ADMIN'}</MenuItem>


                </Select>
              </FormControl>
              <p style={{ color: 'red' }}>{this.state.roleError}</p>
              <TextField type="password" name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
              <p style={{ color: 'red' }}>{this.state.passwordError}</p>
              <p style={{ color: 'red' }}>{this.state.passwordError1}</p>
              <p style={{ color: 'red' }}>{this.state.passwordError2}</p>
              <TextField type="password" name="retType" label="Re enter password" variant="outlined" onChange={this.handleReTypedPassword} />
              <p style={{ color: 'red' }}>{this.state.retypedPasswordError}</p>
              
              {this.state.decodedToken.userType=="SUPER_ADMIN" &&
                   <FormControl fullWidth>
                   <InputLabel id="demo-simple-select-label">Business</InputLabel>
   
                     
   
                   <Select value={this.state.imaginarybusinessid2} onChange={this.handleBusinessChange}
                     labelId="demo-simple-select-label2"
                     id="demo-simple-select2"
                     label="Business"
                   >
                     {this.props.businesses.map((listValue, index) => {
                       return (
                         <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                       );
                     })}
                   </Select>
                 </FormControl>
                  
                  }

            {this.state.decodedToken.userType!=="SUPER_ADMIN" &&
            <FormControl fullWidth>
                   <InputLabel id="demo-simple-select-label">Business</InputLabel>
   
                     
   
                   <Select value={this.state.decodedToken.business} onChange={this.handleBusinessChange}
                     labelId="demo-simple-select-label2"
                     id="demo-simple-select2"
                     label="Business"
                   >
                     
                         <MenuItem value={this.state.decodedToken.business}>{this.state.decodedToken.businessName}</MenuItem>
                     
                   </Select>
                 </FormControl>

                }

              <p style={{ color: 'red' }}>{this.state.businessError}</p>
              <p></p>
            </form>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleUserUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </div>

    );
  }
}

UsersTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UsersTable);
