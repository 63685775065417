import React, { useState } from 'react';
import jwt_decode from "jwt-decode";
import { URL } from '../config';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();


function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch} >
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "email": login,
    "password": password
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch(URL + `api/login`, requestOptions)
    .then((response)=>{ return response.json();
      





    }).then((data) => {
      console.log(data);
      if (data.data==='email or password wrong') {  alert('Invalid Email or Password'); } else {

     

      console.log(data.token);
      localStorage.setItem('token',JSON.stringify(data.token));
      var decoded=jwt_decode(data.token);
      localStorage.setItem('imaginarybusinessname',decoded.businessName)
      var token =  localStorage.getItem('token');
      console.log(localStorage.getItem('token'));
      setError(false);
      setIsLoading(true);
    
      if (!!login && !!password) {  
        setTimeout(() => {
          localStorage.setItem('id_token', 1)
          setError(null)
          setIsLoading(false)
          dispatch({ type: 'LOGIN_SUCCESS' });
    
          history.push('/app/dashboard');
        }, 0);
      } else {
        dispatch({ type: "LOGIN_FAILURE" });
        setError(true);
        setIsLoading(false);
      }
      
      console.log(data.response);

    

    }
    })
    .catch(error => console.log('error', error));




}

function signOut(dispatch, history) {
  console.log('signed out');
  localStorage.removeItem("id_token");
  localStorage.removeItem("token");
  localStorage.removeItem("imaginarybusinessname");
  localStorage.removeItem("imaginarybusinessid");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");


 

  


}
