import React, { Component } from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { TextField, InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import axios from "axios";
import Modal from 'react-bootstrap/Modal'
import './index.css';
import jwt_decode from "jwt-decode";
import { URL } from '../../config';

import PropTypes from 'prop-types';

import {
  Grid,

} from "@material-ui/core";
import Widget from '../../components/Widget/Widget';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
});

class NetworkNodeTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      show2: false,
      show3:false,
      name: '',
      description: '',
      ip: '',
      user: '',
      password: '',
      id: '',
      resources: [],
      nodes: [],
      networks: [],
      network_name: '',
      resource_name: '',
      resource_nodes: [],
      resource_node: '',
      showMessage: false,
      message: '',
      networks: [],
      letsgo: '',
      errors: [],
      error: false,
      nameError: '',
      ipError: '',
      userError: '',
      passwordError: '',
      endpointError: '',
      typeError: '',
      endpoint: '',
      type: '',
      resourceNodeError: '',
      resourceError: '',
      networkError: '',
      resource_id: '',
      network_id: '',
      network: '',
      emailError: '',
      roleError: '',
      businessError: '',
      email: '',
      role: '',
      business: '',
      userid: '',
      lebusinessId: '',
      resource_node2: '',
      businessName: '',
      currentBusinessName: '',
      imaginarybusinessid: '',
      imaginarybusinessid2: '',
      decodedToken: '',
      latitude: '44.439663',
      longitude: '26.096306',
      toBeForceDestroyed:0,
      resourceTypes:[]
    }



  }

  componentDidMount() {
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    this.setState({ decodedToken: decoded });

    this.state.lebusinessId = decoded.business;

    if (localStorage.getItem("imaginarybusinessid") != null) {

      this.state.imaginarybusinessid = localStorage.getItem('imaginarybusinessid');
      this.state.imaginarybusinessid2 = localStorage.getItem('imaginarybusinessid');

    } else {

      this.state.imaginarybusinessid = decoded.business;
      this.state.imaginarybusinessid2 = decoded.business;
    }

    var businessid = this.props.businessid;

    axios.get(URL + `api/resource/all?businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const resources = res.data;

        this.setState({ resources: resources });
        console.log(this.props.businessid);
        console.log("awfwag");

        axios.get(URL + `api/network/all?businessId=` + businessid, {
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem("token").replace(/['"]+/g, '')

          }
        })
          .then(res => {
            const networks = res.data;

            networks.forEach(function (b) {
              if (b.status === 'SUCCESS') this.state.networks.push(b);
            }
              , this)


            this.props.tabledata.forEach(function (v) {
              this.state.networks.forEach(function (b) {
                if (v.network === b.id) v.network_name = b.name;
              }
                , this)
            }
              , this)

           

            this.props.tabledata.forEach(function (x) {

              this.state.resources.forEach(function (y) {
                if (x.resource_id === y.id) {
                  
                  x.resource_name = y.name;
                  x.resource_type=y.type;
                
                
                
                }
                
              }
                , this)


            }
              , this)

            this.setState({});

          })

      });



  }

  rerender() {
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    this.setState({ decodedToken: decoded });

    this.state.lebusinessId = decoded.business;

    if (localStorage.getItem("imaginarybusinessid") != null) {

      this.state.imaginarybusinessid = localStorage.getItem('imaginarybusinessid');
      this.state.imaginarybusinessid2 = localStorage.getItem('imaginarybusinessid');

    } else {

      this.state.imaginarybusinessid = decoded.business;
      this.state.imaginarybusinessid2 = decoded.business;
    }

    var businessid = this.props.businessid;

    axios.get(URL + `api/resource/all?businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const resources = res.data;

        this.setState({ resources: resources });
        console.log(this.props.businessid);
        console.log("awfwag");

        axios.get(URL + `api/network/all?businessId=` + businessid, {
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem("token").replace(/['"]+/g, '')

          }
        })
          .then(res => {
            const networks = res.data;
            this.setState({
              networks: []
            })
            networks.forEach(function (b) {
              if (b.status === 'SUCCESS') this.state.networks.push(b);
            }
              , this)

            this.props.tabledata.forEach(function (v) {
              this.state.networks.forEach(function (b) {
                if (v.network === b.id) v.network_name = b.name;
              }
                , this)
            }
              , this)

            this.props.tabledata.forEach(function (x) {

              this.state.resources.forEach(function (y) {
                if (x.resource_id === y.id) {x.resource_name = y.name;
                  x.resource_type=y.type;
                
                }
              }
                , this)


            }
              , this)

            this.setState({});

          })

      });

  }

  handleClose = () => {
    this.setState({ show: false });
    this.setState({ showMessage: false });
  }

  handleClose2 = () => {
    this.setState({ show2: false });
  }

  handleclose3 = () => {


    this.setState({show3:false});

  }

  handleShow = () => {
    this.setState({ show: true });
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  }
  handleLatitude = (e) => {
    this.setState({ latitude: e.target.value });
  }

  handleLongitude = (e) => {
    this.setState({ longitude: e.target.value });
  }

  handleSelect = (e) => {
    this.setState({ select: e.target.value });
  }

  handleDescChange = (e) => {
    this.setState({ description: e.target.value });
  }

  handleResourceNodeChange = (e) => {
    this.setState({ resource_node: e.target.value });
  }

  handleResourceChange = (e) => {
    this.setState({ resource_id: e.target.value });
    if (this.state.resources.find(x => x.id === e.target.value).type === 'AWS') { this.state.resource_nodes = []; this.state.resource_nodes.push('EC2_LINUX', 'EC2_WINDOWS'); }
    if (this.state.resources.find(x => x.id === e.target.value).type === 'VMWARE') { this.state.resource_nodes = []; this.state.resource_nodes.push('WINDOWS_SERVER_2016', 'LINUX_UBUNTU'); }
    if (this.state.resources.find(x => x.id === e.target.value).type === 'PHYSICAL') { this.state.resource_nodes = []; this.state.resource_nodes.push('ROUTER_OS', 'LINUX_UBUNTU', 'WINDOWS_SERVER_2016'); }
    if (this.state.resources.find(x => x.id === e.target.value).type === 'LINUX_DOCKER') { this.state.resource_nodes = []; this.state.resource_nodes.push('DOCKER'); }
    if (this.state.resources.find(x => x.id === e.target.value).type !== 'PHYSICAL' && this.state.resources.find(x => x.id === e.target.value).type !== 'LINUX_DOCKER' && this.state.resources.find(x => x.id === e.target.value).type !== 'VMWARE' && this.state.resources.find(x => x.id === e.target.value).type !== 'AWS') { this.state.resource_nodes = []; }

  }


  handleNetworkChange = (e) => {
    this.setState({ network: e.target.value });
    this.setState({ network_id: e.target.value });
  }

  handleIpChange = (e) => {
    this.setState({ ip: e.target.value });
  }

  handleUserChange = (e) => {
    this.setState({ user: e.target.value });
  }

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });

  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });

  }

  handleRoleChange = (e) => {
    this.setState({ role: e.target.value });

  }

  handleBusinessChange = (e) => {
    this.setState({ imaginarybusinessid2: e.target.value, business: e.target.value });

  }

  handleAdminBusinessChange = (e) => {

    var businessid = e.target.value;
    localStorage.setItem('imaginarybusinessid', businessid);
    window.location.reload();

  }

  handleEndpointChange = (e) => {
    this.setState({ endpoint: e.target.value });
  }

  handlextratext = (e) => {
    this.setState({ extradata: e.target.value });

  }

  changetype = (e) => {
    this.setState({ type: e.target.value });
  }

  pagereload = (e) => {
    console.log('hei');
    this.setState({ show2: false, show2: false, showMessage: false });
    this.props.rerenderParentCallback();
    this.rerender();


  }


  handleNodeDelete = param => e => {


    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      }

    };
    fetch(URL + 'api/networkNodeDelete?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Node has been deleted succesfully' });


  }











  handleUpdate = (param) => e => {
    this.setState({ show2: param.show2, name: param.name, description: param.description, ip: param.ip, user: param.user, password: param.password, id: param.id });
  }

  handleUpdate3 = (param) => e => {
    this.setState({ show2: true, name: param.name, description: param.description, endpoint: param.endpoint, user: param.user, extradata: param.extra_data, id: param.id, extradata: param.extradata, type: param.type });
  }

  handleUpdate4 = (param) => e => {





    this.setState({ show2: true, email: param.email, role: param.role, userid: param.userid, business: param.business });
    console.log(param.business);






  }





  handleUpdate5 = (param) => e => {

    this.setState({ show2: true, name: param.name, description: param.description, businessId: param.businessId });
  }




  handleNodeUpdate = (param) => e => {
    this.setState({ show2: true, name: param.name, description: param.description, ip: param.ip, resource_id: param.resource_id, network: param.network, id: param.id, resource_name: param.resource_name, network_name: param.network_name, resource_node: param.resource_node, resource_node2: param.resource_node,latitude:param.latitude,longitude:param.longitude });
    if (this.state.resources.find(x => x.id === param.resource_id).type === 'AWS') { this.state.resource_nodes = []; this.state.resource_nodes.push('EC2_LINUX', 'EC2_WINDOWS'); }
    if (this.state.resources.find(x => x.id === param.resource_id).type === 'VMWARE') { this.state.resource_nodes = []; this.state.resource_nodes.push('WINDOWS_SERVER_2016', 'LINUX_UBUNTU'); }
    if (this.state.resources.find(x => x.id === param.resource_id).type === 'LINUX_DOCKER') { this.state.resource_nodes = []; this.state.resource_nodes.push('DOCKER'); }
    if (this.state.resources.find(x => x.id === param.resource_id).type === 'PHYSICAL') { this.state.resource_nodes = []; this.state.resource_nodes.push('ROUTER_OS', 'LINUX_UBUNTU', 'WINDOWS_SERVER_2016'); }
    if (this.state.resources.find(x => x.id === param.resource_id).type !== 'PHYSICAL' && this.state.resources.find(x => x.id === param.resource_id).type !== 'LINUX_DOCKER' && this.state.resources.find(x => x.id === param.resource_id).type !== 'VMWARE' && this.state.resources.find(x => x.id === param.resource_id).type !== 'AWS') { this.state.resource_nodes = []; }

    console.log(this.state.resource_node2);
  }


  handleNodeDestroy = param => e => {

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },
    };
    fetch(URL + 'api/networkNode/destroy?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Node has been destroyed succesfully' });

  }


  handleNodeForceDestroyConfirmation = param => e => {
    
    this.setState({toBeForceDestroyed:param,show3:true});
    
    



  }



  handleNodeForceDestroy = () => {

    console.log("bla");
    this.setState({show3:false});

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },
    };
    fetch(URL + 'api/networkNode/destroyForce?id=' + this.state.toBeForceDestroyed, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Node has been force destroyed' });

  }

  handleNodeDeploy = param => e => {


    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },
    };
    fetch(URL + 'api/networkNode/deploy?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Node has been deployed succesfully' });


  }





  handleUpdateNode = () => {

    var error = false;

    this.setState({ nameError: '', resourceNodeError: '', resourceError: '', networkError: '' });

    var data = { name: this.state.name, description: this.state.description, resource_id: this.state.resource_id, network: this.state.network, ip: this.state.ip, resource_node: this.state.resource_node, businessId: this.props.businessid };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (this.state.resource_node === '' || this.state.resource_node === null) { this.setState({ resourceNodeError: 'Please enter a network node' }); error = true }
    if (this.state.resource_id === '' || this.state.resource_id === null) { this.setState({ resourceError: 'Please enter a resource' }); error = true }
    if (this.state.network === '' || this.state.network === null) { this.setState({ networkError: 'Please enter a network' }); error = true }
    console.log(data);
    this.setState({ show2: true });


    if (error === false) {

      data={
        ...data,
        latitude:this.state.latitude,
        longitude:this.state.longitude
      }

      this.setState({ show2: false });
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/networkNodeUpdate?id=' + this.state.id, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.error_message != undefined) { this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.error_message }); }

          if (data.error_message === undefined) this.setState({ postId: data.id, allgood: true, showMessage: true, message: 'Node has been updated succesfully' });

        })

    }
  }

  createNode = () => {



    var error = false;

    this.setState({ nameError: '', resourceNodeError: '', resourceError: '', networkError: '' });

    var data = { name: this.state.name, description: this.state.description, resource_id: this.state.resource_id, network: this.state.network_id, ip: this.state.ip, resource_node: this.state.resource_node, businessId: this.props.businessid, type: 'REGULAR' };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (this.state.resource_node === '' || this.state.resource_node === null) { this.setState({ resourceNodeError: 'Please enter a network node' }); error = true }
    if (this.state.resource_id === '' || this.state.resource_id === null) { this.setState({ resourceError: 'Please enter a resource' }); error = true }
    if (this.state.network_id === '' || this.state.network_id === null) { this.setState({ networkError: 'Please enter a network' }); error = true }
    this.setState({ show: true });


    if (error === false) {
      data={
        ...data,
        latitude:this.state.latitude,
        longitude:this.state.longitude
      }

      this.setState({ show: false });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/addNetworkNode', requestOptions)
        .then(response => response.json())
        .then(data => {
          this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.data.error_message });
          if (data.data.error_message === undefined) this.setState({ postId: data.id, allgood: true, showMessage: true, message: 'Node has been added succesfully' });

        })

    }
  }



  render() {
    var tablenumber = this.props.tablenumber;
    var columns = this.props.columns;
    var zadata = [];
    var zadata2 = [];
    for (let i = 0; i < this.state.resources.length; i++) {
      zadata.push({ name: this.state.resources[i].name, id: this.state.resources[i].id });
    }
    for (let i3 = 0; i3 < this.state.networks.length; i3++) {
      zadata2.push({ name: this.state.networks[i3].name, id: this.state.networks[i3].id });
    }
    const { classes } = this.props;


    return (
      <div>
        <Modal show={this.state.showMessage} onHide={this.pagereload} centered>
          <Modal.Body>{this.state.message}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.pagereload}>
              Close
            </Button>

          </Modal.Footer>
        </Modal>

       
          <Button style={{ borderColor: "#e94e95", backgroundColor: "#e94e95" }} variant="success" size="lg" onClick={this.handleShow} >Create Node</Button>
        
        <p></p>

        {this.state.decodedToken.userType == "SUPER_ADMIN" && <div>


          <Grid container spacing={4}>

            <Grid item lg={9} md={12} sm={21} xs={33}>
              <Widget disableWidgetMenu={true}
                title={"You are currently switched on Business:"}
                uppertitle
                bodyClass={classes.fullHeightBody}
                className={classes.card}
              >


                <h5> <b>{this.props.currentBusinessName}</b></h5>


              </Widget>
            </Grid>
          </Grid>
        </div>



        }

        <p></p>
        <Card className="text-center">
          <Card.Header ><b><h3>Nodes</h3></b></Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {this.props.columns.map((listValue, index) => {
                    return (
                      <th>{columns[index]}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>

                {this.props.tabledata.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td hidden>{listValue.id}</td>
                      <td hidden>{listValue.network}</td>
                      <td hidden>{listValue.resource_id}</td>
                      <td><a href={"#/app/nodeDetails/" + listValue.id}>{listValue.name}</a></td>
                      <td>{listValue.description}</td>
                      <td>{listValue.network_name}</td>
                      <td>{listValue.ip}</td>
                      <td>{listValue.resource_name}</td>
                      <td>{listValue.resource_node}</td>
                      <td>{listValue.resource_type}</td>
                      <td>{listValue.status}</td>



                      {listValue.status == 'ADDED' ? <td>  <Button variant="success" style={{ borderColor: "#605dba", backgroundColor: "#605dba" }} onClick={this.handleNodeUpdate({ show2: true, name: listValue.name, description: listValue.description, ip: listValue.ip, network: listValue.network, resource_id: listValue.resource_id, id: listValue.id, resource_name: listValue.resource_name, network_name: listValue.network_name, resource_node: listValue.resource_node,latitude:listValue.latitude,longitude:listValue.longitude })}>Update</Button>&nbsp;<Button style={{ borderColor: "#e94e95", backgroundColor: "#e94e95" }} variant="danger" onClick={this.handleNodeDelete(listValue.id)}>Delete</Button>&nbsp;<Button onClick={this.handleNodeDeploy(listValue.id)}>Deploy</Button> </td> : null}
                      {listValue.status == 'SUCCESS' ? <td><Button variant="danger" style={{ borderColor: "#e94e95", backgroundColor: "#e94e95" }} onClick={this.handleNodeDestroy(listValue.id)}>Destroy</Button></td> : null}
                      {listValue.status == 'ERROR' ? <td><Button variant="danger" style={{ borderColor: "#e94e95", backgroundColor: "#e94e95" }} onClick={this.handleNodeForceDestroyConfirmation(listValue.id)}>Force Destroy</Button></td> : null}
                      {listValue.status == 'IN_PROGRESS' ? <td ><h5>No actions</h5></td> : null}


                    </tr>
                  );
                })}

              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Modal dialogClassName='custom-dialog' show={this.state.show} onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Create Node</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-box">
              <form onSubmit={this.handleSubmit}>
                <TextField name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                <p style={{ color: 'red' }}>{this.state.nameError}</p>
                <TextField name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                <p></p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Resource</InputLabel>
                  <Select onChange={this.handleResourceChange}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type"
                  >
                    {zadata.map((listValue, index) => {
                      return (
                        <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                      );
                    })}
                  </Select>
                  <p style={{ color: 'red' }}>{this.state.resourceError}</p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Resource Node</InputLabel>
                    <Select onChange={this.handleResourceNodeChange}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Type"
                    >
                      {this.state.resource_nodes.map((listValue, index) => {
                        return (
                          <MenuItem value={listValue}>{listValue}</MenuItem>
                        );
                      })}
                    </Select>

                  </FormControl>
                  <p style={{ color: 'red' }}>{this.state.resourceNodeError}</p>

                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Network</InputLabel>
                  <Select onChange={this.handleNetworkChange}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type">
                    {zadata2.map((listValue, index) => {
                      return (
                        <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <p style={{ color: 'red' }}>{this.state.networkError}</p>
                <br></br>
                <TextField name="lat" label="Enter latitude" variant="outlined" value={this.state.latitude} onChange={this.handleLatitude} />
                <br></br>
                <br></br>
                <TextField name="long" label="Enter longitude" variant="outlined" value={this.state.longitude} onChange={this.handleLongitude} />
              </form>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.createNode}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal dialogClassName='custom-dialog' show={this.state.show3} onHide={this.handleclose3} centered >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to force delete this node?</Modal.Title>
          </Modal.Header>
         
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleclose3}>
              No
            </Button>
            <Button variant="primary" onClick={this.handleNodeForceDestroy}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>



        <Modal dialogClassName='custom-dialog' show={this.state.show2} onHide={this.handleClose2} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update Node</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-box">
              <form >
                <TextField value={this.state.name} name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                <p style={{ color: 'red' }}>{this.state.nameError}</p>
                <TextField value={this.state.description} name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                <p></p>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Resource</InputLabel>
                  <Select value={this.state.resource_id} onChange={this.handleResourceChange}
                    labelId="demo-simple-select-label"
                    id="demo-simple-selectt"
                    label="Type"
                  >

                    {zadata.map((listValue, index) => {
                      return (
                        <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                      );
                    })}
                  </Select>
                  <p style={{ color: 'red' }}>{this.state.resourceError}</p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Resource Node</InputLabel>
                    <Select value={this.state.resource_node} onChange={this.handleResourceNodeChange}
                      labelId="demo-simple-select-label2"
                      id="demo-simple-select2"
                      label="Type"
                    >
                      {this.state.resource_nodes.map((listValue, index) => {
                        return (
                          <MenuItem value={listValue}>{listValue}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p style={{ color: 'red' }}>{this.state.resourceNodeError}</p>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Network</InputLabel>
                  <Select value={this.state.network} onChange={this.handleNetworkChange}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type"
                  >
                    {zadata2.map((listValue, index) => {
                      return (
                        <MenuItem value={listValue.id}>{listValue.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <p style={{ color: 'red' }}>{this.state.networkError}</p>
                <br></br>
                <TextField name="lat" label="Enter latitude" variant="outlined" value={this.state.latitude} onChange={this.handleLatitude} />
                <br></br>
                <br></br>
                <TextField name="long" label="Enter longitude" variant="outlined" value={this.state.longitude} onChange={this.handleLongitude} />
              </form>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleUpdateNode}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )


  }



}

NetworkNodeTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NetworkNodeTable);
