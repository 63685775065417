import React, { useState } from "react";
import { useEffect } from "react";
import { URL } from "../../../config";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



// context



function Manual(props) {

    var [manual, setManual] = useState(1);
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setManual(event.target.value);
      };

  
    return(
      <div className="App">
        
 
 
        

        <iframe style={{width:"100%",height:"700px"}} src={URL+'manual'}></iframe>

 
        
      </div>
    );
  }
  

export default Manual;
