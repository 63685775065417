import React, { useState } from 'react';

export default function Iframe({ url }) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  function handleLoad() {
    setLoaded(true);
  }

  function handleError() {
    setError(true);
  }

  return (
    <div>
      <iframe width="100%" height="1000"
        src={url}
        onLoad={handleLoad}
        onError={handleError}
      />
      {!loaded && !error && <p>Loading iframe...</p>}
      {loaded && <p>Iframe loaded successfully!</p>}
      {error && <p>Iframe failed to load.</p>}
    </div>
  );
}