import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  BorderAll as TableIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import jwt_decode from "jwt-decode";
// styles
import useStyles from "./styles";
import makeStyles from "@material-ui/styles/makeStyles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import drawerimage from "../../images/img3.jpg"
import History from "../../pages/history/History";
import { Paper } from "@mui/material";
import Diamond from './scudo_250x100.png';
import Poza from './img3.jpg'
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import Link from "@material-ui/core/Link";
import RoomIcon from '@mui/icons-material/Room';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BusinessIcon from '@mui/icons-material/Business';
import HistoryIcon from '@mui/icons-material/History';
import ApiIcon from '@mui/icons-material/Api';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import emblema1 from "../../images/emblema1.jpg";
import logo3 from "../Sidebar/logo3.png";
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import MenuBookIcon from '@mui/icons-material/MenuBook';






function reloadPage() {
  // The last "domLoading" Time //
  var currentDocumentTimestamp =
    new Date(performance.timing.domLoading).getTime();
  // Current Time //
  var now = Date.now();
  // Ten Seconds //
  var tenSec = 10 * 1000;
  // Plus Ten Seconds //
  var plusTenSec = currentDocumentTimestamp + tenSec;
  if (now > plusTenSec) {
    window.location.reload();
  } else { }
}




function Sidebar({ location,props }) {




  var structure;

var name = localStorage.getItem('token');



if (name) {
  var token = name;
  var decoded = jwt_decode(name);
  if (decoded.userType == 'SUPER_ADMIN') {




    structure = [
      { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
      {
        id: 1,
        label: "Resources",
        link: "/app/resources",
        icon: <TypographyIcon />,
      },
      { id: 2, label: "Network", link: "/app/network", icon: <NetworkCheckIcon /> },
      {
        id: 3,
        label: "Node",
        link: "/app/node",
        icon: <RoomIcon />,
      },
      {
        id: 4,
        label: "Users",
        link: "/app/users",
        icon: <SupervisorAccountIcon />,
      },
      {
        id: 5,
        label: "Business",
        link: "/app/business",
        icon: <BusinessIcon />,
      },
      {
        id: 6,
        label: "Monitoring",
        link: "/app/monitoring",
        icon: <LegendToggleIcon />,
      },
      {
        id: 7,
        label: "History",
        link: "/app/history",
        icon:<HistoryIcon />,
      },
      {
        id: 8,
        label: "API",
        link: "/app/swagger",
        icon:<ApiIcon />,
      },{
        id: 9,
        label: "Queues",
        link: "/app/queues",
        icon:<HourglassBottomIcon />,
      },
      {
        id: 10,
        label: "Micro-services",
        link: "/app/microservices",
        icon:<MiscellaneousServicesIcon />,
      },
      {
        id: 11,
        label: "Orchestrator",
        link: "/app/orchestrator",
        icon:<AutoFixHighIcon />,
      },
      {
        id: 12,
        label: "Manual",
        link: "/app/superManual",
        icon:<MenuBookIcon />,
      }

    ];



  } else if (decoded.userType == 'ADMIN') {

    structure = [
      { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
      {
        id: 1,
        label: "Resources",
        link: "/app/resources",
        icon: <TypographyIcon />,
      },
      { id: 2, label: "Network", link: "/app/network", icon: <NetworkCheckIcon /> },
      {
        id: 3,
        label: "Node",
        link: "/app/node",
        icon: <RoomIcon />,
      },
      {
        id: 4,
        label: "Users",
        link: "/app/users",
        icon: <SupervisorAccountIcon />,
      },
      {
        id: 6,
        label: "Monitoring",
        link: "/app/monitoring",
        icon: <LegendToggleIcon />,
      },
      {
        id: 7,
        label: "Manual",
        link: "/app/manual",
        icon:<MenuBookIcon />,
      }
    ];

  } else if (decoded.userType == 'REGULAR_USER') {

    structure = [
      { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
      {
        id: 1,
        label: "Resources",
        link: "/app/resources",
        icon: <TypographyIcon />,
      },
      { id: 2, label: "Network", link: "/app/network", icon: <NetworkCheckIcon /> },
      {
        id: 3,
        label: "Node",
        link: "/app/node",
        icon: <RoomIcon />,
      },
      {
        id: 6,
        label: "Monitoring",
        link: "/app/monitoring",
        icon: <LegendToggleIcon />,
      },
      {
        id: 7,
        label: "Manual",
        link: "/app/manual",
        icon:<MenuBookIcon />,
      }
    ];





  } else 

  structure = [
    { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
      {
        id: 1,
        label: "Resources",
        link: "/app/resources",
        icon: <TypographyIcon />,
      },
      { id: 2, label: "Network", link: "/app/network", icon: <NetworkCheckIcon /> },
      {
        id: 3,
        label: "Node",
        link: "/app/node",
        icon: <RoomIcon />,
      },
      {
        id: 4,
        label: "Users",
        link: "/app/users",
        icon: <SupervisorAccountIcon />,
      },
      {
        id: 5,
        label: "Business",
        link: "/app/business",
        icon: <BusinessIcon />,
      },
      {
        id: 6,
        label: "Manual",
        link: "/app/manual",
        icon:<MenuBookIcon />,
      }
  ];
}



  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  



  return (

  <id>
    
    <Drawer 
    
   
      variant={isPermanent ? "permanent" : "temporary"}
     
      className={[classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })]}
     
      classes={{
       
        paper: classNames({
         
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div id="">
      <div id="" style={{backgroundColor:"#493892",marginBottom:50}} className={classes.toolbar} >


      
         <button  style={{backgroundColor:"#536DFE",padding:0,marginLeft:50,marginTop:20,border:"none",background:"none"}}>
          <Link>
            <img  src={logo3} style={{ width: 130, height: 40 }} />
          </Link>
        </button>

      </div>
      <div id="" className={classes.mobileBackButton}>
        <IconButton  onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List   className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
      </div>
    <Paper id="imagecontainer"></Paper>
    </Drawer>
</id>

  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
