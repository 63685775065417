import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import Table from 'react-bootstrap/Table'
import { URL } from '../../config';
import axios from "axios";
import Customtable from '../network/customtable';
// Component's Base CSS
import './index.css';
import jwt_decode from "jwt-decode";
import BusinessTable from './businessTable';


export default class Business extends React.Component {

  state = {
    data: [],
    currentBusinessName:''

  }




  componentDidMount() {
    

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    console.log(decoded);



    if (decoded.userType==='SUPER_ADMIN'){ 

      if (localStorage.getItem("imaginarybusinessid") != null) {
  
        var businessid = localStorage.getItem('imaginarybusinessid').replace(/['"]+/g, '');
  
      } else {
  
        businessid = decoded.business;
  
      }
  
    } else businessid=decoded.business

    

    axios.get(URL + `api/business/all?businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        this.setState({ data });
        console.log(this.state.data);
       
        this.setState({ businessid: businessid });

        this.state.data.forEach(function (b) {

          if (b.id == businessid) {console.log('yes');this.setState({ currentBusinessName: b.name });document.getElementById("b1").innerHTML=b.name;localStorage.setItem('imaginarybusinessname',b.name)}
        }
          , this)

       


        



        if (data.message === 'jwt expired') {

          console.log('signed out');
          localStorage.removeItem("id_token");
          localStorage.removeItem("token");
          localStorage.removeItem("imaginarybusinessid");
          localStorage.removeItem('imaginarybusinessname');
          window.location.replace('/login');
          alert('You have been signed out. Please enter your credentials');

        };

      })
      

  }

  rerenderParentCallback = (e) => {
    console.log('rerendered');
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);

    if (decoded.userType==='SUPER_ADMIN'){ 

      if (localStorage.getItem("imaginarybusinessid") != null) {
  
        var businessid = localStorage.getItem('imaginarybusinessid').replace(/['"]+/g, '');
  
      } else {
  
        businessid = decoded.business;
  
      }
  
    } else businessid=decoded.business

   

    axios.get(URL + `api/business/all?businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        this.setState({ data });
        console.log(this.state.data);
        this.setState({ businessid: businessid });
       
        this.state.data.forEach(function (b) {

          if (b.id == businessid) {console.log('yes');this.setState({ currentBusinessName: b.name });document.getElementById("b1").innerHTML=b.name; localStorage.setItem('imaginarybusinessname',b.name)}
        }
          , this)

          

        if (data.message === 'jwt expired') {

          console.log('signed out');
          localStorage.removeItem("id_token");
          localStorage.removeItem("token");
          localStorage.removeItem("imaginarybusinessid");
          localStorage.removeItem('imaginarybusinessname');
          window.location.replace('/login');
          alert('You have been signed out. Please enter your credentials');

        };

      })


    this.setState({});
   
    

  }


  render() {

    if (this.state.data.message !== 'jwt expired') {

      return (
        <BusinessTable columns={['Name', 'Description']}
          tabledata={this.state.data}
          businessid={this.state.businessid}
          rerenderParentCallback={this.rerenderParentCallback}
          businesses={this.state.data}
          currentBusinessName={this.state.currentBusinessName}
        >



        </BusinessTable>
      )

    } else { return null; }
  }
}
