import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import Table from 'react-bootstrap/Table'
import { URL } from '../../config';
import axios from "axios";
import Customtable from '../network/customtable';
// Component's Base CSS
import './index.css';
import jwt_decode from "jwt-decode";
import UsersTable from "./usersTable";


export default class Users extends React.Component {

  state = {
    data: [],
    businesses: [],
    businessid:''

  }




  componentDidMount() {
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    console.log(decoded);


    if (decoded.userType == 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

        var businessid = localStorage.getItem('imaginarybusinessid')

      } else {

        businessid = decoded.business;

      }
    }else {

      businessid=decoded.business;

    }

    if (decoded.userType == 'SUPER_ADMIN') {

      axios.get(URL + `api/user/all?businessId=` + businessid, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')

        }
      })
        .then(res => {
          const data = res.data;
          this.setState({ data });
          console.log(this.state.data);
          axios.get(URL + `api/business/all?businessId=` + businessid, {
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem("token").replace(/['"]+/g, '')

            }
          })
            .then(res => {
              const businesses = res.data;
              this.setState({ businesses:businesses });
              console.log(this.state.businesses);
              this.setState({ businessid: businessid });


              if (data.message === 'jwt expired') {

                console.log('signed out');
                localStorage.removeItem("id_token");
                localStorage.removeItem("token");
                localStorage.removeItem("imaginarybusinessid");
                localStorage.removeItem('imaginarybusinessname');
                window.location.replace('/login');
                alert('You have been signed out. Please enter your credentials');

              };

            })
        })


    } else {

      axios.get(URL + `api/user/all?businessId=` + businessid, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')

        }
      })
        .then(res => {
          const data = res.data;
          this.setState({ data });
          console.log(this.state.data);
          this.setState({ businessid: businessid });


          if (data.message === 'jwt expired') {

            console.log('signed out');
            localStorage.removeItem("id_token");
            localStorage.removeItem("token");
            localStorage.removeItem("imaginarybusinessid");
            localStorage.removeItem('imaginarybusinessname');
            window.location.replace('/login');
            alert('You have been signed out. Please enter your credentials');

          };

        })



    }
  }

  rerenderParentCallback = (e) => {

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    console.log(decoded);



    if (decoded.userType == 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

        var businessid = localStorage.getItem('imaginarybusinessid')

      } else {

        businessid = decoded.business;

      }
    } else {

      businessid=decoded.business;

    }

    if (decoded.userType == 'SUPER_ADMIN') {

      axios.get(URL + `api/user/all?businessId=` + businessid, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')

        }
      })
        .then(res => {
          const data = res.data;
          this.setState({ data });
          console.log(this.state.data);
          axios.get(URL + `api/business/all?businessId=` + businessid, {
            headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem("token").replace(/['"]+/g, '')

            }
          })
            .then(res => {
              const businesses = res.data;
              this.setState({ businesses:businesses });
              console.log(this.state.businesses);
              this.setState({ businessid: businessid });


              if (data.message === 'jwt expired') {

                console.log('signed out');
                localStorage.removeItem("id_token");
                localStorage.removeItem("token");
                localStorage.removeItem("imaginarybusinessid");
                localStorage.removeItem('imaginarybusinessname');
                window.location.replace('/login');
                alert('You have been signed out. Please enter your credentials');

              };

            })
        })


    } else {

      axios.get(URL + `api/user/all?businessId=` + businessid, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')

        }
      })
        .then(res => {
          const data = res.data;
          this.setState({ data });
          console.log(this.state.data);
          this.setState({ businessid: businessid });


          if (data.message === 'jwt expired') {

            console.log('signed out');
            localStorage.removeItem("id_token");
            localStorage.removeItem("token");
            localStorage.removeItem("imaginarybusinessid");
            localStorage.removeItem('imaginarybusinessname');
            window.location.replace('/login');
            alert('You have been signed out. Please enter your credentials');

          };

        })



    }

  }




  render() {


    if (this.state.data.message !== 'jwt expired') {

      return (
        <UsersTable columns={['Email', 'Role', 'createdAt', 'updatedAt','Is Active']}
          tabledata={this.state.data}
          businesses={this.state.businesses}
          rerenderParentCallback={this.rerenderParentCallback}
          currentbusinessname={localStorage.getItem('imaginarybusinessname').replace(/['"]+/g, '')}
          businessid={this.state.businessid}
        >

        </UsersTable>
      )


    } else { return null };

  }
}
