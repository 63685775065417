import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import axios from "axios";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";


// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import { URL } from "../../config";
import { getURL } from "next/dist/shared/lib/utils";
import Map from "../Map/Map";
import History from "./History";



export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();

  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  var [resources, setResources] = useState([]);
  var [VMWcount, setVMWcount] = useState(0);
  var [PhysicalCount, setPhysicalCount] = useState(0);
  var [AWSCount, setAWSCount] = useState(0);
  var [DOCKERcount, setDockerCount] = useState(0);
  var [networks, setNetworks] = useState([]);
  var [networkSuccess, setNetworkSuccess] = useState(0);
  var [networkAdded, setNetworkAdded] = useState(0);
  var [networkInProgress, setNetworkInProgress] = useState(0);
  var [nodes, setNodes] = useState([]);
  var [nodeAddedNumber, setNodeAddedNumber] = useState(0);
  var [nodeinPorgressNumber, setnodeinPorgressNumber] = useState(0);
  var [nodeSuccessNumber, setnodeSuccessNumber] = useState(0);
  var [users, setUsers] = useState([]);
  var [usersAdminNumber, setusersAdminNumber] = useState(0);
  var [usersSuperAdminNumber, setusersSuperAdminNumber] = useState(0);
  var [usersRegularUserNumber, setusersRegularUserNumber] = useState(0);
  var [role, setRole] = useState('SUPER_ADMIN');

  const mainChartData = getMainChartData();

const PieChartData = [
  { name: "VMWARE", value: VMWcount, color: "primary" },
  { name: "AWS", value: AWSCount, color: "warning" },
  { name: "DOCKER", value: DOCKERcount, color: "secondary" },
  { name: "PHYSICAL", value: PhysicalCount, color: "success" }
 
];

const PieChartData2 = [
  { name: "ADDED", value: networkAdded, color: "primary" },
  { name: "SUCCESS", value: networkSuccess, color: "warning" },
  { name: "IN PROGRESS", value: networkInProgress, color: "secondary" }
 
];


const PieChartData3 = [
  { name: "ADDED", value: nodeAddedNumber, color: "primary" },
  { name: "IN PROGRESS", value: nodeinPorgressNumber, color: "warning" },
  { name: "SUCCESS", value: nodeSuccessNumber, color: "secondary" }
 
];

const PieChartData4 = [
  { name: "ADMIN", value: usersAdminNumber, color: "primary" },
  { name: "SUPER ADMIN", value: usersSuperAdminNumber, color: "warning" },
  { name: "REGULAR USER", value: usersRegularUserNumber, color: "secondary" }
 
];


  useEffect(()=>{

    console.log(theme.palette[0]);


    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    setRole(decoded.userType);

    if (decoded.userType == 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

        var businessid = localStorage.getItem('imaginarybusinessid')

      } else {

        businessid = decoded.business;

      }

    } else businessid = decoded.business


    axios.get(URL + `api/user/all?businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        setUsers(data);

        let v1=0;
        let v2=0;
        let v3=0;

      for (let i=0;i<data.length;i=i+1){

        if (data[i].role==="ADMIN") { v1+=1; setusersAdminNumber(v1); }
        if (data[i].role==="SUPER_ADMIN") {v2+=1; setusersSuperAdminNumber(v2); }
        if (data[i].role==="REGULAR_USER") {v3+=1; setusersRegularUserNumber(v3);}
        
      }

    

        if (data.message === 'jwt expired') {

     

        };

      }).then(res=>{




      })


      

},[])


  useEffect(()=>{

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    setRole(decoded.userType);

    if (decoded.userType == 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

        var businessid = localStorage.getItem('imaginarybusinessid')

      } else {

        businessid = decoded.business;

      }

    } else businessid = decoded.business


    axios.get(URL + `api/networkNode/all?type=REGULAR&&businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        setNodes(data);

        let v1=0;
        let v2=0;
        let v3=0;

      for (let i=0;i<data.length;i=i+1){

        if (data[i].status==="ADDED") { v1+=1; setNodeAddedNumber(v1); }
        if (data[i].status==="IN_PROGRESS") {v2+=1; setnodeinPorgressNumber(v2); }
        if (data[i].status==="SUCCESS") {v3+=1; setnodeSuccessNumber(v3);}
        
      }

      for (let k=0;k<data.length;k=k+1){

  
      
  
        
      }

  

        if (data.message === 'jwt expired') {



        };

      }).then(res=>{




      })


      

},[])

 
  useEffect(()=>{


    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    setRole(decoded.userType);





    if (decoded.userType==='SUPER_ADMIN'){ 

      if (localStorage.getItem("imaginarybusinessid") != null) {
  
        var businessid = localStorage.getItem('imaginarybusinessid')
  
      } else {
  
        businessid = decoded.business;
  
      }
  
    } else businessid=decoded.business

    axios.get(URL + `api/network/all?businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        setNetworks(data);

        let v1=0;
        let v2=0;
        let v3=0;

      for (let i=0;i<data.length;i=i+1){

        if (data[i].status==="ADDED") { v1+=1; setNetworkAdded(v1); }
        if (data[i].status==="SUCCESS") {v2+=1; setNetworkSuccess(v2); }
        if (data[i].status==="IN_PROGRESS") {v3+=1; setNetworkInProgress(v3);}
        
      }
      
        if (data.message === 'jwt expired') {

  
        };

        

        

      })




},[])

  useEffect(()=>{

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    setRole(decoded.userType);

    if (decoded.userType == 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

        var businessid = localStorage.getItem('imaginarybusinessid')

      } else {

        businessid = decoded.business;

      }

    } else businessid = decoded.business


    axios.get(URL + `api/resource/all?businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        setResources(data);

        let v1=0;
        let v2=0;
        let v3=0;
        let v4=0;

      for (let i=0;i<data.length;i=i+1){

        if (data[i].type==="VMWARE") { v1+=1; setVMWcount(v1); }
        if (data[i].type==="LINUX_DOCKER") {v2+=1; setDockerCount(v2); }
        if (data[i].type==="PHYSICAL") {v4+=1; setPhysicalCount(v4); }
        if (data[i].type==="AWS") {v3+=1; setAWSCount(v3);}
        
      }

    

        if (data.message === 'jwt expired') {

          console.log('signed out');
          localStorage.removeItem("id_token");
          localStorage.removeItem("token");
          localStorage.removeItem("imaginarybusinessid");
          localStorage.removeItem("imaginarybusinessname");
          window.location.replace('/login');
          alert('You have been signed out. Please enter your credentials');

        };

      }).then(res=>{




      })


      

},[])





  return (


    <>
  
      <PageTitle title="Dashboard"  />
   
      <Grid container spacing={4}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget 
            title="Resources"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div  className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
                {resources.length}
              </Typography>
                </Grid>
                <Grid item xs={6}>
              <LineChart
                width={100}
                height={30}
                data={[
                  { value: VMWcount },
                  { value: AWSCount },
                  { value: DOCKERcount },
                  {value: PhysicalCount }
                
                ]}
              >
                <Line
                  type="natural"
                  dataKey="value"
                  stroke={theme.palette.success.main}
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  VMWARE
                </Typography>
                <Typography size="md">{VMWcount}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  AWS
                </Typography>
                <Typography size="md">{AWSCount}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  Docker
                </Typography>
                <Typography size="md">{DOCKERcount}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  Physical
                </Typography>
                <Typography size="md">{PhysicalCount}</Typography>
              </Grid>
            </Grid>
           
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  <PieChart>
                    <Pie
                      data={PieChartData}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {PieChartData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={theme.palette[entry.color].main}
                          
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {PieChartData.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
      
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title="Networks"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
                {networks.length}
              </Typography>
                </Grid>
                <Grid item xs={6}>
              <LineChart
                width={100}
                height={30}
                data={[
                  { value: networkAdded },
                  { value: networkSuccess },
                  { value: networkInProgress },
                 
                ]}
              >
                <Line
                  type="natural"
                  dataKey="value"
                  stroke={theme.palette.success.main}
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  ADDED
                </Typography>
                <Typography size="md">{networkAdded}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  SUCCESS
                </Typography>
                <Typography size="md">{networkSuccess}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  InProgress
                </Typography>
                <Typography size="md">{networkInProgress}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  <PieChart>
                    <Pie
                      data={PieChartData2}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {PieChartData2.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={theme.palette[entry.color].main}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {PieChartData2.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title="Nodes"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
                {nodes.length}
              </Typography>
                </Grid>
                <Grid item xs={6}>
              <LineChart
                width={100}
                height={30}
                data={[
                  { value: nodeAddedNumber },
                  { value: nodeinPorgressNumber },
                  { value: nodeSuccessNumber },
                 
                ]}
              >
                <Line
                  type="natural"
                  dataKey="value"
                  stroke={theme.palette.success.main}
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  ADDED
                </Typography>
                <Typography size="md">{nodeAddedNumber}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  InProgress
                </Typography>
                <Typography size="md">{nodeinPorgressNumber}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  SUCCESS
                </Typography>
                <Typography size="md">{nodeSuccessNumber}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  <PieChart>
                    <Pie
                      data={PieChartData3}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {PieChartData3.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={theme.palette[entry.color].main}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {PieChartData3.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
            
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          {role==='REGULAR_USER' ? null :  <Widget
            title="Users"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
                {users.length}
              </Typography>
                </Grid>
                <Grid item xs={6}>
              <LineChart
                width={100}
                height={30}
                data={[
                  { value: usersAdminNumber },
                  { value: usersSuperAdminNumber },
                  { value: usersRegularUserNumber },
                
                ]}
              >
                <Line
                  type="natural"
                  dataKey="value"
                  stroke={theme.palette.success.main}
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  ADMIN
                </Typography>
                <Typography size="md">{usersAdminNumber}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  SUPER_ADMIN
                </Typography>
                <Typography size="md">{usersSuperAdminNumber}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                  Regular User
                </Typography>
                <Typography size="md">{usersRegularUserNumber}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  <PieChart>
                    <Pie
                      data={PieChartData4}
                      innerRadius={30}
                      outerRadius={40}
                      dataKey="value"
                    >
                      {PieChartData4.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={theme.palette[entry.color].main}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {PieChartData4.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Grid>
             
            </Grid>


          </Widget> }
         
         
        </Grid>

        <Grid item>



        </Grid>
     
      </Grid>
     
    
      <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={4} >


              <Widget 
            title="Last added networks"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer} >
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
                {networks.slice(0,3).length}
              </Typography>
                </Grid>
                <Grid item xs={6}>
          
                </Grid>
              </Grid>
            </div>
         
           

            
            {networks.slice(0, 3).map((listValue, index) => {
                  return (
                    <div>
                   <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                    <Typography color="text" colorBrightness="secondary" noWrap>
                  <b>Name:</b><br></br>{listValue.name}
                </Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                <b>Ip:</b><br></br>{listValue.ip}
              </Typography>
              </Grid>
              <Grid item xs={4}>
               <Typography color="text" colorBrightness="secondary" noWrap>
               <b>Status:</b><br></br>{listValue.status}
             </Typography>
             </Grid>
             </Grid>
                
             </div>

                  
                  );
                })}


            
              

            
          </Widget>  


                </Grid>
             
          





              
              
                <Grid item xs={12} md={6} lg={4} >


              <Widget
            title="Last added Nodes"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
                {nodes.slice(0,3).length}
              </Typography>
                </Grid>
                <Grid item xs={6}>
          
                </Grid>
              </Grid>
            </div>
         
           

            
            {nodes.slice(0, 3).map((listValue, index) => {
                  return (
                    <div>
                   <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
          
              <Grid item xs={4}>
                    <Typography color="text" colorBrightness="secondary" noWrap>
                  <b>Name:</b><br></br>{listValue.name}
                </Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                <b>Ip:</b><br></br>{listValue.ip}
              </Typography>
              </Grid>
              <Grid item xs={4}>
               <Typography color="text" colorBrightness="secondary" noWrap>
               <b>Status:</b><br></br>{listValue.status}
             </Typography>
             </Grid>
             </Grid>
                
             </div>

                  
                  );
                })}


            
              

            
          </Widget>  


                </Grid>
             
             




          
               
                <Grid item xs={12} md={6} lg={4} >


              <Widget
              
            title="Last added Resources"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
              <Typography size="xl" weight="medium" noWrap>
                {resources.slice(0,3).length}
              </Typography>
                </Grid>
                <Grid item xs={6}>
          
                </Grid>
              </Grid>
            </div>
         
           

            
            {resources.slice(0, 3).map((listValue, index) => {
                  return (
                    <div>
                   <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                    <Typography color="text" colorBrightness="secondary" noWrap>
                  <b>Name:</b><br></br>{listValue.name}
                </Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography color="text" colorBrightness="secondary" noWrap>
                <b>Endpoint:</b><br></br>{listValue.endpoint}
              </Typography>
              </Grid>
              <Grid item xs={4}>
               <Typography color="text" colorBrightness="secondary" noWrap>
               <b>Type:</b><br></br>{listValue.type}
             </Typography>
             </Grid>
             </Grid>
                
             </div>

                  
                  );
                })}


            
              

            
          </Widget>  


                </Grid>
                </Grid>
                
              



                  <br></br>
           
                {role==='SUPER_ADMIN' ? <History></History> : null}
            
         
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
