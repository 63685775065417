import React from 'react';
import axios from "axios";
import { URL } from '../../config';
// Component's Base CSS
import './index.css';

import NetworkNodeTable from "./networkNodeTable";
import jwt_decode from "jwt-decode";




export default class NetworkNode extends React.Component {

  state = {
    data: [],
    businesses:[],
    currentBusinessName:'',
    businessid:'',
    resourceInfo:{}
  }

  componentDidMount() {

    var token = localStorage.getItem("token").replace(/['"]+/, '');
    var decoded = jwt_decode(token);
    var businessid;

    if (decoded.userType == 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

         businessid = localStorage.getItem('imaginarybusinessid');
         console.log(businessid);
      } else {

        businessid = decoded.business;
        console.log(decoded);
      }

    } else  {console.log(decoded);businessid = decoded.business;}

  

    axios.get(URL + "api/networkNode/all?type=REGULAR&&businessId="+ businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        this.setState({ data });
       
        this.setState({ businessid: businessid });



        if (data.message === 'jwt expired') {

          console.log('signed out');
          localStorage.removeItem("id_token");
          localStorage.removeItem("token");
          localStorage.removeItem("imaginarybusinessid");
          localStorage.removeItem('imaginarybusinessname');
          window.location.replace('/login');
          alert('You have been signed out. Please enter your credentials');

        };

      

      })

  }

  rerenderParentCallback = (e) => {

    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    var businessid

    if (decoded.userType === 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

        businessid= localStorage.getItem('imaginarybusinessid');

      } else {

        businessid = decoded.business;

      }

    } else businessid = decoded.business;

       

    axios.get(URL + `api/networkNode/all?type=REGULAR&&businessId=` + businessid, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')

      }
    })
      .then(res => {
        const data = res.data;
        this.setState({ data });
        console.log(this.state.data);
        this.setState({ businessid: businessid });
   

        if (data.message === 'jwt expired') {

          console.log('signed out');
          localStorage.removeItem("id_token");
          localStorage.removeItem("token");
          localStorage.removeItem('imaginarybusinessname');
          localStorage.removeItem("imaginarybusinessid");
          window.location.replace('/login');
          alert('You have been signed out. Please enter your credentials');

        };

       

      })

      axios.get(URL + `api/resourceById?id=` + this.state.data.resource_id , {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
  
        }
      })
        .then(res => {
          const data = res.data;
          this.setState({ resourceInfo:data });
         
     
  
  
         
  
        })

    this.setState({});

  }

  render() {

    var token = localStorage.getItem("token").replace(/['"]+/, '');
    var decoded = jwt_decode(token);
    var businessid;

    if (decoded.userType == 'SUPER_ADMIN') {

      if (localStorage.getItem("imaginarybusinessid") != null) {

         businessid = localStorage.getItem('imaginarybusinessid');
         console.log(businessid);
      } else {

        businessid = decoded.business;
        console.log(decoded);
      }

    } else  {console.log(decoded);businessid = decoded.business;}
   
    if (this.state.data.message !== 'jwt expired') {
      console.log(this.state.businessid);
      return (
        <div >
          <NetworkNodeTable
           columns={['Name', 'Description', 'Network', 'Ip', 'Resource', 'Resource Node', 'Resource Type', 'Status']}
            tabledata={this.state.data}
            businessid={businessid}
            rerenderParentCallback={this.rerenderParentCallback}
            currentBusinessName={localStorage.getItem("imaginarybusinessname").replace(/['"]+/g, '')}
          >



          </NetworkNodeTable>

        </div>
      )
    } else { return null; }
  }

}
