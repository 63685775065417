import React, { Component } from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { TextField } from "@material-ui/core";
import axios from "axios";
import Modal from 'react-bootstrap/Modal'
import './index.css';
import jwt_decode from "jwt-decode";
import Widget from '../../components/Widget/Widget';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Grid,
 
} from "@material-ui/core";
import { URL } from '../../config';

const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
});

 class Networktable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      show2: false,
      name: '',
      description: '',
      ip: '',
      user: '',
      password: '',
      id: '',
      resources: [],
      nodes: [],
      networks: [],
      network_name: '',
      resource_name: '',
      resource_nodes: [],
      resource_node: '',
      showMessage: false,
      message: '',
      networks: [],
      letsgo: '',
      errors: [],
      error: false,
      nameError: '',
      ipError: '',
      userError: '',
      passwordError: '',
      endpointError: '',
      typeError: '',
      endpoint: '',
      type: '',
      resourceNodeError: '',
      resourceError: '',
      networkError: '',
      resource_id: '',
      network_id: '',
      network: '',
      emailError: '',
      roleError: '',
      businessError: '',
      email: '',
      role: '',
      business: '',
      userid: '',
      lebusinessId: '',
      resource_node2: '',
      businessName: '',
      currentBusinessName: '',
      imaginarybusinessid: '',
      imaginarybusinessid2: '',
      decodedToken:''

    }



  }

  componentDidMount() {
    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    this.setState({decodedToken:decoded});
  }

  handleClose = () => {
    this.setState({ show: false });
    this.setState({ showMessage: false });
  }

  handleClose2 = () => {
    this.setState({ show2: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  }

  handleSelect = (e) => {
    this.setState({ select: e.target.value });
  }

  handleDescChange = (e) => {
    this.setState({ description: e.target.value });
  }


  handleNetworkChange = (e) => {
    this.setState({ network: e.target.value });
    this.setState({ network_id: e.target.value });
  }

  handleIpChange = (e) => {
    this.setState({ ip: e.target.value });
  }

  handleUserChange = (e) => {
    this.setState({ user: e.target.value });
  }

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });

  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });

  }

  handleRoleChange = (e) => {
    this.setState({ role: e.target.value });

  }


  handleEndpointChange = (e) => {
    this.setState({ endpoint: e.target.value });
  }

  handlextratext = (e) => {
    this.setState({ extradata: e.target.value });

  }

  changetype = (e) => {
    this.setState({ type: e.target.value });
  }

  pagereload = (e) => {
    console.log('hei');
    this.setState({ show2: false, show2: false, showMessage: false });
    this.props.rerenderParentCallback();

  }

  createNetwork = () => {

    var error = false;

    this.setState({ nameError: '', ipError: '', userError: '', passwordError: '' });

    var data = { name: this.state.name, description: this.state.description, ip: this.state.ip, network: this.state.network_id, resource_id: this.state.resource_id, user: this.state.user, password: this.state.password, businessId: this.props.businessid };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (data.user === '' || data.user === null) { this.setState({ userError: 'Please enter a user' }); error = true }
    if (data.password.length < 4) { this.setState({ passwordError: 'Password must have at least 4 characters' }); error = true }
    this.setState({ show: true });


    if (error === false) {

      this.setState({ show: false });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/addNetwork', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Network has been added succesfully' });


    }

  }

  handleDelete = param => e => {

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },

    };
    fetch(URL + 'api/networkDelete?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => {
        this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.data.error });
        if (data.data.error === undefined) this.setState({ postId: data.id, allgood: true, showMessage: true, message: 'Network has been deleted succesfully' });

      })


  }

  handleUpdate2 = () => {


    var error = false;

    this.setState({ nameError: '', ipError: '', userError: '', passwordError: '' });

    var data = { name: this.state.name, description: this.state.description, ip: this.state.ip, user: this.state.user, password: this.state.password, businessId: this.props.businessid };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }

    if (data.user === '' || data.user === null) { this.setState({ userError: 'Please enter a user' }); error = true }

    if (data.password.length > 0 && data.password.length < 4) { this.setState({ passwordError: 'Password must have at least 4 characters' }); error = true }

    this.setState({ show2: true });


    if (error === false) {

      this.setState({ show2: false });
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/networkUpdate?id=' + this.state.id, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Network has been updated succesfully' });










    }
  }


  handleUpdate = (param) => e => {
    this.setState({ show2: param.show2, name: param.name, description: param.description, ip: param.ip, user: param.user, password: param.password, id: param.id });
  }

  handleUpdate3 = (param) => e => {
    this.setState({ show2: true, name: param.name, description: param.description, endpoint: param.endpoint, user: param.user, extradata: param.extra_data, id: param.id, extradata: param.extradata, type: param.type });
  }

  handleUpdate4 = (param) => e => {





    this.setState({ show2: true, email: param.email, role: param.role, userid: param.userid, business: param.business });
    console.log(param.business);






  }




  handleUpdate5 = (param) => e => {

    this.setState({ show2: true, name: param.name, description: param.description, businessId: param.businessId });
  }






  handleDestroy = param => e => {

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },
    };
    fetch(URL + 'api/network/destroy?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => {
        this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.data.error });
        if (data.data.error === undefined) this.setState({ postId: data.id, allgood: true, showMessage: true, message: 'Network has been destroyed succesfully' }); else {

          this.setState({allgood: false, showMessage: true, message: 'Error! Please delete all nodes that include this network' });
        }

      })


  }



  handleDeploy = param => e => {



    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },
    };
    fetch(URL + 'api/network/deploy?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Network has been deployed succesfully' });


  }

  render() {
    var columns = this.props.columns;
    var zadata = [];
    var zadata2 = [];
    for (let i = 0; i < this.state.resources.length; i++) {
      zadata.push({ name: this.state.resources[i].name, id: this.state.resources[i].id });
    }
    for (let i3 = 0; i3 < this.state.networks.length; i3++) {
      zadata2.push({ name: this.state.networks[i3].name, id: this.state.networks[i3].id });
    }
    const { classes } = this.props;

    return (
      <div>
        <Modal show={this.state.showMessage} onHide={this.pagereload} centered>
          <Modal.Body>{this.state.message}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.pagereload}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


        <Button style={{borderColor:"#e94e95",backgroundColor:"#e94e95"}} variant="success" size="lg" onClick={this.handleShow} >Create Network</Button>

    

<p></p>
        

{this.state.decodedToken.userType=="SUPER_ADMIN"&&  <div>


<Grid container spacing={4}>

<Grid item lg={9} md={12} sm={21} xs={33}>
<Widget
 disableWidgetMenu={true}
title={"You are currently switched on Business:"}
uppertitle
bodyClass={classes.fullHeightBody}
className={classes.card}
>


    <h5> <b>{this.props.currentBusinessName}</b></h5>


    </Widget>
</Grid>
</Grid>
    </div>
    
    
    
     }

        
        <p></p>
        <Card className="text-center">
          <Card.Header ><b><h3>Network</h3></b></Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {this.props.columns.map((listValue, index) => {
                    return (
                      <th>{columns[index]}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {this.props.tabledata.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td hidden>{listValue.id}</td>
                      <td>{listValue.name}</td>
                      <td>{listValue.description}</td>
                      <td hidden>{listValue.ip}</td>
                      <td>{listValue.user}</td>
                      <td>{listValue.status}</td>
                      {listValue.status == 'ADDED' ? <td> <Button style={{borderColor:"#605dba",backgroundColor:"#605dba"}}  variant="success" onClick={this.handleUpdate({ show2: true, name: listValue.name, description: listValue.description, ip: listValue.ip, user: listValue.user, status: listValue.status, id: listValue.id, password: '' })}>Update</Button>&nbsp;<Button style={{borderColor:"#e94e95",backgroundColor:"#e94e95"}} variant="danger" onClick={this.handleDelete(listValue.id)}>Delete</Button>&nbsp;<Button onClick={this.handleDeploy(listValue.id)}>Deploy</Button></td> : null}
                      {listValue.status == 'SUCCESS' ? <td><Button  variant="danger" onClick={this.handleDestroy(listValue.id)}>Destroy</Button></td> : null}
                      {listValue.status == 'ERROR' ? <td><Button style={{borderColor:"#e94e95",backgroundColor:"#e94e95"}} variant="danger" onClick={this.handleDestroy(listValue.id)}>Destroy</Button></td> : null}
                      {listValue.status == 'IN_PROGRESS' ? <td ><h5>No actions</h5></td> : null}
                    </tr>
                  );
                })}

              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Modal dialogClassName='custom-dialog' show={this.state.show} onHide={this.handleClose} centered >
          <Modal.Header closeButton>
            <Modal.Title>Create Network</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-box">
              <form onSubmit={this.handleSubmit}>
                <TextField name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                <p style={{ color: 'red' }}>{this.state.nameError}</p>
                <TextField name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                <p style={{ color: 'red' }}>{this.state.descriptionError}</p>
                {/* <TextField name="ip" label="Enter ip" variant="outlined" onChange={this.handleIpChange} />
                <p style={{ color: 'red' }}>{this.state.ipError}</p> */}
                <TextField name="user" label="Enter user" variant="outlined" onChange={this.handleUserChange} />
                <p style={{ color: 'red' }}>{this.state.userError}</p>
                <TextField name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
                <p style={{ color: 'red' }}>{this.state.passwordError}</p>
              </form>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.createNetwork}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName='custom-dialog' show={this.state.show2} onHide={this.handleClose2} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update Network</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-box">
              <form >
                <TextField value={this.state.name} name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                <p style={{ color: 'red' }}>{this.state.nameError}</p>
                <TextField value={this.state.description} name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                <p></p>
                {/* <TextField value={this.state.ip} name="ip" label="Enter ip" variant="outlined" onChange={this.handleIpChange} />
                <p style={{ color: 'red' }}>{this.state.ipError}</p> */}
                <TextField value={this.state.user} name="user" label="Enter user" variant="outlined" onChange={this.handleUserChange} />
                <p style={{ color: 'red' }}>{this.state.userError}</p>
                <TextField value={this.state.password} name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
                <p style={{ color: 'red' }}>{this.state.passwordError}</p>
              </form>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleUpdate2}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

}

Networktable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Networktable);
