import React, { useState } from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import { Box, IconButton, Link } from '@material-ui/core';
import Icon from '@mdi/react';
import Manual from '../../pages/manual/manual/manual';


//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Resources from "../../pages/resources/Resources";

import NetworkNode from "../../pages/networkNode/NetworkNode";
import Maps from "../../pages/maps";
import Network from "../../pages/network/Network";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

// context
import { useLayoutState } from "../../context/LayoutContext";
import Users from "../../pages/users/Users";
import Business from "../../pages/business/Business";
import NodeDetails from "../../pages/networkNode/NodeDetails";
import History from '../../pages/history/History';
import Swagger from '../../pages/Swagger/Swagger';
import Monitoring from "../../pages/Monitoring/MonitorNode";
import Queues from '../../pages/Queues/Queues';
import Microservices from '../../pages/microServices/microservices';
import Orchestrator from '../../pages/Orchestrator/Orchestrator';
import MonitorNodeDetails from '../../pages/Monitoring/MonitorNodeDetails';
import superManual from '../../pages/manual/manual/superManual';


function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar rerender={useState({})} />
        <div id="imagecontainer"
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch >
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/resources" component={Resources} />
            <Route path="/app/network" component={Network} />
            <Route path="/app/node" component={NetworkNode} />
            <Route path="/app/history" component={History} />
            <Route path="/app/users" component={Users} />
            <Route path="/app/business" component={Business} />
            <Route path="/app/Monitoring" component={Monitoring} />
            <Route path="/app/monitoringDetails/:id" component={MonitorNodeDetails} />
            <Route path="/app/swagger" component={Swagger} />
            <Route path="/app/queues" component={Queues} />
            <Route path="/app/microservices" component={Microservices} />
            <Route path="/app/orchestrator" component={Orchestrator} />
            <Route path="/app/nodeDetails/:id" component={NodeDetails} />
            <Route path="/app/superManual" component={superManual} />
            <Route path="/app/manual" component={Manual} />
         
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
          </Switch>

        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
