import React, { Component } from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { TextField, InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import Modal from 'react-bootstrap/Modal'
import './index.css';
import jwt_decode from "jwt-decode";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Grid,

} from "@material-ui/core";
import Widget from '../../components/Widget/Widget';
import { URL } from '../../config';
import aws from '../../images/aws.png';
import docker from '../../images/docker.png'
import vmware from '../../images/vmware.png'
import ubuntu from '../../images/ubuntu.png'


const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
});

class ResourcesTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      show2: false,
      name: '',
      description: '',
      ip: '',
      user: '',
      password: '',
      id: '',
      resources: [],
      nodes: [],
      networks: [],
      network_name: '',
      resource_name: '',
      resource_nodes: [],
      resource_node: '',
      showMessage: false,
      message: '',
      networks: [],
      letsgo: '',
      errors: [],
      error: false,
      nameError: '',
      ipError: '',
      userError: '',
      passwordError: '',
      endpointError: '',
      typeError: '',
      endpoint: '',
      type: '',
      resourceNodeError: '',
      resourceError: '',
      networkError: '',
      resource_id: '',
      network_id: '',
      network: '',
      emailError: '',
      roleError: '',
      businessError: '',
      email: '',
      role: '',
      business: '',
      userid: '',
      lebusinessId: '',
      resource_node2: '',
      businessName: '',
      currentBusinessName: '',
      imaginarybusinessid: '',
      imaginarybusinessid2: '',
      port:'22',
      decodedToken: ''

    }



  }

  componentDidMount() {


    var token = localStorage.getItem("token").replace(/['"]+/g, '');
    var decoded = jwt_decode(token);
    this.setState({ decodedToken: decoded });

  }

  handleClose = () => {
     this.setState({ port: '22' });
    this.setState({ show: false });
    this.setState({ showMessage: false });
  }

  handleClose2 = () => {
     this.setState({ port: '22' });
    this.setState({ show2: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  }

  handleSelect = (e) => {
    this.setState({ select: e.target.value });
  }

  handleDescChange = (e) => {
    this.setState({ description: e.target.value });
  }

  handleResourceNodeChange = (e) => {
    this.setState({ resource_node: e.target.value });
  }




  handleUserChange = (e) => {
    this.setState({ user: e.target.value });
  }

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });

  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });

  }

  handleEndpointChange = (e) => {
    this.setState({ endpoint: e.target.value });
  }

  handlextratext = (e) => {
    this.setState({ extradata: e.target.value });

  }
 handlePort = (e) => {
    this.setState({ port: e.target.value });

  }


  changetype = (e) => {
    this.setState({ type: e.target.value });
  }

  pagereload = (e) => {
    console.log('hei');
    this.setState({ show2: false, show2: false, showMessage: false });
    this.props.rerenderParentCallback();

  }





  handleResourceDelete = param => e => {


    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      },

    };
    fetch(URL + 'api/resourceDelete?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => {
        this.setState({ postId: data.id, allgood: true, showMessage: true, message: data.data.error });
        if (data.data.error === undefined) this.setState({ postId: data.id, allgood: true, showMessage: true, message: 'Resource has been deleted succesfully' });

      })






  }



  handleBusinessDelete = param => e => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token").replace(/['"]+/g, '')
      }

    };
    fetch(URL + 'api/deleteBusiness?id=' + param, requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
    this.setState({ allgood: true });
    this.setState({ showMessage: true, message: 'Business has been deleted succesfully' });
  }



  createResource = () => {


    var error = false;

    this.setState({ nameError: '', userError: '', passwordError: '', typeError: '', endpointError: '' });

     var data = { name: this.state.name, description: this.state.description, type: this.state.type, user: this.state.user, password: this.state.password, extra_data: this.state.extradata, endpoint: this.state.endpoint, businessId: this.props.businessid,port:this.state.port };
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (data.type === '' || data.type === null) { this.setState({ typeError: 'Please enter a type' }); error = true }
    if (data.user === '' || data.user === null) { this.setState({ userError: 'Please enter a user' }); error = true }
    if (data.password === '' || data.password === null) { this.setState({ passwordError: 'Please enter a password' }); error = true }
    if (data.password.length < 4) { this.setState({ passwordError: 'Password must have at least 4 characters' }); error = true }
    if (data.endpoint === '' || data.endpoint === null) { this.setState({ endpointError: 'Please enter an Endpoint' }); error = true }

    this.setState({ show: true });


    if (error === false) {


      this.setState({ show: false });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/addResource?businessId=' + this.props.businessid, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Resource has been added succesfully' });
    }

  }


  handleUpdate = (param) => e => {
    this.setState({ show2: param.show2, name: param.name, description: param.description, ip: param.ip, user: param.user, password: param.password, id: param.id ,port:param.port });
  }

  handleUpdate3 = (param) => e => {
     this.setState({ show2: true, name: param.name, description: param.description, endpoint: param.endpoint, user: param.user, extradata: param.extra_data, id: param.id, extradata: param.extradata, type: param.type ,port:param.port });
  }

  handleUpdate4 = (param) => e => {





    this.setState({ show2: true, email: param.email, role: param.role, userid: param.userid, business: param.business });
    console.log(param.business);

  }




  handleUpdate5 = (param) => e => {

    this.setState({ show2: true, name: param.name, description: param.description, businessId: param.businessId });
  }




  handleUpdateResource = () => {



    var error = false;

    this.setState({ nameError: '', userError: '', passwordError: '', endpointError: '', typeError: '' });
    var data = { name: this.state.name, description: this.state.description, user: this.state.user, password: this.state.password, endpoint: this.state.endpoint, extra_data: this.state.extra_data, type: this.state.type, businessId: this.props.businessid ,port:this.state.port};
    if (data.name === '' || data.name === null) { this.setState({ nameError: 'Please enter a name' }); error = true }
    if (data.user === '' || data.user === null) { this.setState({ userError: 'Please enter a user' }); error = true }
    if (data.password.length > 0 && data.password.length < 4) { this.setState({ passwordError: 'Password must have at least 4 characters' }); error = true }
    if (data.endpoint === '' || data.endpoint === null) { this.setState({ endpointError: 'Please enter a endpoint' }); error = true }
    if (data.type === '' || data.type === null) { this.setState({ typeError: 'Please enter a type' }); error = true }

    console.log(data);

    this.setState({ show2: true });


    if (error === false) {


      this.setState({ show2: false });
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem("token").replace(/['"]+/g, '')
        },
        body: JSON.stringify(data)
      };
      fetch(URL + 'api/resourceUpdate?id=' + this.state.id, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
      this.setState({ allgood: true });
      this.setState({ showMessage: true, message: 'Resource has been updated succesfully' });

    }

  }

  render() {

    const { classes } = this.props;

    var columns = this.props.columns;
    return (
      <div>
        <Modal show={this.state.showMessage} onHide={this.pagereload} centered>
          <Modal.Body>{this.state.message}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.pagereload}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


        <Button style={{ borderColor: "#e94e95", backgroundColor: "#e94e95" }} size="lg" onClick={this.handleShow} >Create Resource</Button>


        <p></p>



        {this.state.decodedToken.userType == "SUPER_ADMIN" && <div>


          <Grid container spacing={4}>

            <Grid item lg={9} md={12} sm={21} xs={33}>
              <Widget
                disableWidgetMenu={true}
                title={"You are currently switched on Business:"}
                uppertitle
                bodyClass={classes.fullHeightBody}
                className={classes.card}
              >


                <h5> <b>{this.props.currentbusinessname}</b></h5>


              </Widget>
            </Grid>
          </Grid>
        </div>



        }
        <p></p>



        <Card className="text-center">
          <Card.Header ><b><h3>Resources</h3></b></Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {this.props.columns.map((listValue, index) => {
                    return (
                      <th key={index}>{columns[index]}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {this.props.tabledata.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td hidden>{listValue.id}</td>
                      <td>{listValue.name}</td>
                      <td>{listValue.description}</td>
                      <td>{listValue.endpoint}</td>
                      <td>{listValue.user}</td>
                      <td>{listValue.extra_data}</td>
                      {listValue.type === 'AWS' ? <td><img style={{ marginRight: 5 }} width="30" height="30" src={aws}></img>{listValue.type}</td> : null}
                      {listValue.type === 'VMWARE' ? <td><img style={{ marginRight: 5 }} width="30" height="30" src={vmware}></img>{listValue.type}</td> : null}
                      {listValue.type === 'PHYSICAL' ? <td><img style={{ marginRight: 5 }} width="30" height="30" src={ubuntu}></img>{listValue.type}</td> : null}
                      {listValue.type === 'LINUX_DOCKER' ? <td><img style={{ marginRight: 5 }} width="30" height="30" src={docker}></img>{listValue.type}</td> : null}
                      {listValue.type !== 'LINUX_DOCKER' && listValue.type !== 'PHYSICAL' && listValue.type !== 'VMWARE' && listValue.type !== 'AWS' ? <td>{listValue.type}</td> : null}
                      <td>{listValue.port}</td>
                      <td><Button style={{ borderColor: "#605dba", backgroundColor: "#605dba" }} variant="success" onClick={this.handleUpdate3({ show2: true, name: listValue.name, description: listValue.description, endpoint: listValue.endpoint, user: listValue.user, extradata: listValue.extra_data, id: listValue.id, type: listValue.type })}>Update</Button>&nbsp;<Button style={{ borderColor: "#e94e95", backgroundColor: "#e94e95" }} variant="danger" onClick={this.handleResourceDelete(listValue.id)}>Delete</Button></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Modal dialogClassName='custom-dialog' show={this.state.show} onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Create Resource</Modal.Title>


          </Modal.Header>
          <Modal.Body>
            <div className="form-box">
              <form onSubmit={this.handleSubmit}>
                <TextField name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                <p style={{ color: 'red' }}>{this.state.nameError}</p>
                <TextField name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                <p></p>
                <TextField name="endpoint" label="Enter endpoint" variant="outlined" onChange={this.handleEndpointChange} />
                <p style={{ color: 'red' }}>{this.state.endpointError}</p>
                <TextField name="user" label="Enter user" variant="outlined" onChange={this.handleUserChange} />
                <p style={{ color: 'red' }}>{this.state.userError}</p>
                <TextField name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
                <p style={{ color: 'red' }}>{this.state.passwordError}</p>
                <TextField name="extratext" label="Enter extra text" variant="outlined" onChange={this.handlextratext} />
                <p></p>
                <TextField name="port" label="Enter port" variant="outlined" value={this.state.port} onChange={this.handlePort} />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select onChange={this.changetype}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type" >
                    <MenuItem value={'VMWARE'}>VMWARE</MenuItem>
                    <MenuItem value={'AWS'}>AWS</MenuItem>
                    <MenuItem value={'PHYSICAL'}>PHYSICAL</MenuItem>
                    <MenuItem value={'LINUX_DOCKER'}>LINUX_DOCKER</MenuItem>
                  </Select>
                </FormControl>
                <p style={{ color: 'red' }}>{this.state.typeError}</p>
              </form>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.createResource}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal dialogClassName='custom-dialog' show={this.state.show2} onHide={this.handleClose2} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update Resource</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-box">
              <form >
                <TextField value={this.state.name} name="name" label="Enter name" variant="outlined" onChange={this.handleNameChange} />
                <p style={{ color: 'red' }}>{this.state.nameError}</p>
                <TextField value={this.state.description} name="description" label="Enter description" variant="outlined" onChange={this.handleDescChange} />
                <p></p>
                <TextField value={this.state.endpoint} name="endpoint" label="Enter endpoint" variant="outlined" onChange={this.handleEndpointChange} />
                <p style={{ color: 'red' }}>{this.state.endpointError}</p>
                <TextField value={this.state.user} name="user" label="Enter user" variant="outlined" onChange={this.handleUserChange} />
                <p style={{ color: 'red' }}>{this.state.userError}</p>
                <TextField value={this.state.password} name="password" label="Enter password" variant="outlined" onChange={this.handlePasswordChange} />
                <p style={{ color: 'red' }}>{this.state.passwordError}</p>
                <TextField value={this.state.extradata} name="extratext" label="Enter extra text" variant="outlined" onChange={this.handlextratext} />
                <p></p>
                <TextField name="port" label="Enter port" variant="outlined" value={this.state.port} onChange={this.handlePort} />
                <p></p>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select value={this.state.type} onChange={this.changetype}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type" >
                    <MenuItem value={'VMWARE'}>VMWARE</MenuItem>
                    <MenuItem value={'AWS'}>AWS</MenuItem>
                    <MenuItem value={'LINUX_DOCKER'}>LINUX_DOCKER</MenuItem>
                    <MenuItem value={'PHYSICAL'}>PHYSICAL</MenuItem>
                  </Select>
                </FormControl>
                <p style={{ color: 'red' }}>{this.state.typeError}</p>
              </form>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleUpdateResource}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )

  }
}

ResourcesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResourcesTable);
